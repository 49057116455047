import React from 'react';
import { UserDetailContainer, UserName, StoreName } from './styled';
import Avatar from '../../../Avatar/Avatar';
import { Column } from '../../../../Assets/Structure/Structure';
import EditProfile from '../EditProfile/EditProfile';

export default class UserDetail extends React.Component{


    handleLoggedIn = (loggedIn) => this.props.loggedIn(loggedIn);


    render(){

        const userRole = localStorage.getItem("role");

        return(
            <UserDetailContainer>
                <Avatar name={this.props.userName}/>
                <Column ml1>
                    <UserName>{this.props.userName}</UserName>
                    <StoreName>{localStorage.getItem('store_name')}</StoreName>
                </Column>
            </UserDetailContainer>
        )
    }
}