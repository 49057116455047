// Libraries
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {CreateAvailabilityDialogContainer} from "./styled";
import {Container, Label} from "../../../../Assets/Structure/Structure";
import {Button} from "@material-ui/core";
import DialogV2 from "../../../../Components/DialogV2/DialogV2";
import {DateTime} from "luxon";
import DatePickerExtended from "../../../../Components/DatePickerExtended/DatePickerExtended";
import TimePickerExtended from "../../../../Components/TimePickerExtended/TimePickerExtended";
import {createStoreAvailability} from "../../../../store/modules/ScheduleAvailabilities/actions";
import InlineLoading from "../../../../Components/InlineLoading/InlineLoading";
import { blueDefault } from "../../../../Assets/colorPallet/colorPallet"

/**
 *
 * @param {boolean} active
 * @param {function} onClose
 */
function CreateAvailabilityDialog ({
    active = false,
    onClose = () => ({}),
    selectedStartTime,
    selectedEndTime,
    dispatchCreateStoreAvailability
}) {
    // States
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [loading, setLoading] = useState(false)


    // Watchers
    useEffect(() => {
        if (selectedStartTime && !selectedEndTime) {
            setStartTime(DateTime.fromISO(selectedStartTime).toISO())
            setEndTime(DateTime.fromISO(selectedStartTime).toISO())
        }

        if (selectedStartTime && selectedEndTime) {
            setStartTime(DateTime.fromISO(selectedStartTime).toISO())
            setEndTime(DateTime.fromISO(selectedEndTime).toISO())
        }
    }, [selectedStartTime, selectedEndTime])

    // Methods
    const handleDateOnChange = (e) => {
        setStartTime(DateTime.fromISO(e).toISO())

        const endTimeLuxon = DateTime.fromISO(endTime)
        const combinedEndDateTime = DateTime.fromObject({
            year: e.year,
            month: e.month,
            day: e.day,
            hour: endTimeLuxon.hour,
            minute: endTimeLuxon.minute,
            second: endTimeLuxon.second
        })

        setEndTime(combinedEndDateTime.toISO())
    }

    const handleStartTimeOnChange = (e) => {
        const startDate = DateTime.fromISO(startTime)
        const combinedStartDateTime = DateTime.fromObject({
            year: startDate.year,
            month: startDate.month,
            day: startDate.day,
            hour: e.hour,
            minute: e.minute,
            second: e.second
        })

        setStartTime(combinedStartDateTime.toISO())
    }

    const handleEndTimeOnChange = (e) => {
        const startDate = DateTime.fromISO(startTime)
        const combinedEndDateTime = DateTime.fromObject({
            year: startDate.year,
            month: startDate.month,
            day: startDate.day,
            hour: e.hour,
            minute: e.minute,
            second: e.second
        })

        setEndTime(combinedEndDateTime.toISO())
    }

    const handleCreateStoreAvailability = async () => {
        try {
            setLoading(true)
            await dispatchCreateStoreAvailability({
                startTime,
                endTime
            })

            setLoading(false)
            onClose()
        } catch (error) {
            setLoading(false)
        }
    }


    return (
        <CreateAvailabilityDialogContainer>
            <DialogV2
            active={active}
            onClose={() => onClose()}
            maxWidth="sm"
            title="Create Availability">
                <form>
                    <Container padding="0 40px 40px">
                        <Container>
                            <Label>Date</Label>
                            <Container>
                                <DatePickerExtended
                                onChange={handleDateOnChange}
                                value={selectedStartTime}/>
                            </Container>
                        </Container>
                        <Container flex alignCenter mt="20px">
                            <Container>
                                <Label>Start</Label>
                                <Container>
                                    <TimePickerExtended
                                    value={selectedStartTime}
                                    onChange={handleStartTimeOnChange}/>
                                </Container>
                            </Container>
                            <Container ml="20px">
                                <Label>End</Label>
                                <Container>
                                    <TimePickerExtended
                                    value={selectedEndTime}
                                    onChange={handleEndTimeOnChange}/>
                                </Container>
                            </Container>
                        </Container>
                        <Container flex fluid alignCenter justifyEnd mt="40px">
                            <Container>
                                <Button onClick={() => onClose()}>Cancelar</Button>
                            </Container>
                            <Container ml="20px">
                                <Button
                                onClick={handleCreateStoreAvailability}>
                                    {loading ? <InlineLoading color={blueDefault}/> : "Salvar"}
                                </Button>
                            </Container>
                        </Container>
                    </Container>

                </form>
            </DialogV2>
        </CreateAvailabilityDialogContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    dispatchCreateStoreAvailability({ startTime, endTime }) {
        dispatch(createStoreAvailability({ startTime, endTime }))
    }
})

export default connect(null, mapDispatchToProps)(CreateAvailabilityDialog)
