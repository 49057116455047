// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

// Components
import Schedule from "../../../Components/Schedule/Schedule"

// Styles
import { ScheduleEventsContainer } from "./styled"
import {DateTime} from "luxon";
import {fetchStoreAvailabilities} from "../../../store/modules/ScheduleAvailabilities/actions";
import {fetchScheduleEvents, getScheduleEventById} from "../../../store/modules/ScheduleEvents/actions"
import CreateScheduleEventDialog from "./CreateScheduleEventDialog/CreateScheduleEventDialog";
import UpdateScheduleEventDialog from "./UpdateScheduleEventDialog/UpdateScheduleEventDialog";


function ScheduleEvents ({
    storeAvailabilities,
    dispatchFetchStoreAvailabilities,
    scheduleEvents,
    dispatchFetchScheduleEvents,
    dispatchGetScheduleEventById
}) {
    // States
    const [storeBackgroundAvailabilities, setStoreBackgroundAvailabilities] = useState([])
    const [weekRange, setWeekRange] = useState(null)
    const [selectedStartTime, setSelectedStartTime] = useState(null)
    const [selectedEndTime, setSelectedEndTime] = useState(null)
    const [showCreateScheduleEventDialog, setShowCreateScheduleEventDialog] = useState(false)
    const [showUpdateScheduleEventDialog, setShowUpdateScheduleEventDialog] = useState(false)
    const [selectedScheduleEvent, setSelectedScheduleEvent] = useState(null)


    // Watch
    useEffect(() => {
        if (weekRange) {

            const startOfTheWeek = DateTime.fromISO(weekRange.startTime).toISODate()
            const endOfTheWeek = DateTime.fromISO(weekRange.endTime).toISODate()

            dispatchFetchStoreAvailabilities({
                startTime: startOfTheWeek,
                endTime: endOfTheWeek
            })

            dispatchFetchScheduleEvents({
                startTime: startOfTheWeek,
                endTime: endOfTheWeek
            })
        }
    }, [weekRange])

    // Watchers
    useEffect(() => {
        if (storeAvailabilities && storeAvailabilities.length > 0) {
            setStoreBackgroundAvailabilities(storeAvailabilities.map((av) => {
                return {
                    ...av,
                    display: 'background'
                }
            }))
        }
    }, [storeAvailabilities])

    // Methods
    const handleDateRangeOnChange = (dateRangeInfo) => {
        setWeekRange({
            startTime: dateRangeInfo.startStr,
            endTime: dateRangeInfo.endStr
        })
    }

    const handleDateClick = (e) => {
        return
        const selectedStartTime = e.dateStr
        setSelectedStartTime(selectedStartTime)
        setShowCreateScheduleEventDialog(true)
    }

    const handleDateOnSelect = (e) => {
        return
        setSelectedStartTime(e.startStr)
        setSelectedEndTime(e.endStr)
        setShowCreateScheduleEventDialog(true)
    }


    const handleEventOnClick = (e) => {
        const {event} = e
        setShowUpdateScheduleEventDialog(true)

        const eventId = event.id
        const scheduleEvent = dispatchGetScheduleEventById(eventId)

        setSelectedScheduleEvent(scheduleEvent)
    }


    return (
        <>
            <ScheduleEventsContainer className="scheduler-wraper">
                <Schedule
                    dateClick={handleDateClick}
                    select={handleDateOnSelect}
                    eventClick={handleEventOnClick}
                    datesSet={handleDateRangeOnChange}
                    events={[...storeBackgroundAvailabilities, ...scheduleEvents]} />
            </ScheduleEventsContainer>
            {showCreateScheduleEventDialog &&  (
                <CreateScheduleEventDialog
                    active={showCreateScheduleEventDialog}
                    onClose={() => setShowCreateScheduleEventDialog(false)}
                    selectedStartTime={selectedStartTime}
                    selectedEndTime={selectedEndTime}/>
            )}
            {showUpdateScheduleEventDialog && (
                <UpdateScheduleEventDialog
                    active={showUpdateScheduleEventDialog}
                    onClose={() => setShowUpdateScheduleEventDialog(false)}
                    selectedScheduleEvent={selectedScheduleEvent}/>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    storeAvailabilities: state.scheduleAvailabilities.storeAvailabilities,
    scheduleEvents: state.scheduleEvents.scheduleEvents
})

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchStoreAvailabilities({ startTime, endTime }) {
        dispatch(fetchStoreAvailabilities({ startTime, endTime }))
    },

    dispatchFetchScheduleEvents({ startTime, endTime }) {
        dispatch(fetchScheduleEvents({ startTime, endTime }))
    },
    dispatchGetScheduleEventById(eventId) {
        const scheduleEvent = dispatch(getScheduleEventById(eventId))
        return scheduleEvent
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEvents)
