import styled from "styled-components";

export const UserDetailContainer = styled.div`
  display: flex;
  align-items: center;
  `;

export const UserName = styled.label`
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: #ffffff;
`;

export const StoreName = styled.label`
  margin-top: 0.25em;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.7);
`;
