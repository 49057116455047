// Libraries
import React, { useState } from "react"
import { connect } from "react-redux"
import {Button} from "@material-ui/core";

// Structure components
import { Container, Text } from "../../../../../Assets/Structure/Structure"

// Styles
import { DeleteAvailabilityContainer } from "./styled"

// Components
import InlineLoading from "../../../../../Components/InlineLoading/InlineLoading";
import {blueDefault} from "../../../../../Assets/colorPallet/colorPallet";

// Stores
import { deleteScheduleEvent } from "../../../../../store/modules/ScheduleEvents/actions"


function DeleteScheduleEvent({
    onClose = () => ({}),
    onCancel = () => ({}),
    dispatchDeleteScheduleEvent = () => ({}),
    selectedScheduleEvent
}) {
    // States
    const [loading, setLoading] = useState(false)

    //Methods
    const handleDeleteAvailability = async () => {
        try {
            setLoading(true)
            await dispatchDeleteScheduleEvent({ scheduleEventId: selectedScheduleEvent.id })
            onClose()
        } catch (error) {
            throw error
        } finally {
            setLoading(false)
        }
    }


    return (
        <DeleteAvailabilityContainer>
            <Container pl="40px" pb="40px">
                <Text mt="0" mb="0">Are you sure about deleting this event?</Text>
                <Container pr="40px">
                    <Container flex fluid alignCenter justifyEnd mt="40px">
                        <Container>
                            <Button onClick={() => onCancel()}>Cancel</Button>
                        </Container>
                        <Container ml="20px">
                            <Button
                                onClick={handleDeleteAvailability}>
                                {loading ? <InlineLoading color={blueDefault}/> : "Delete"}
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </DeleteAvailabilityContainer>
    )
}


const mapDispatchToProps = (dispatch) => ({
    async dispatchDeleteScheduleEvent({ scheduleEventId }) {
        await dispatch(deleteScheduleEvent({ scheduleEventId }))
    }
})


export default connect(null, mapDispatchToProps)(DeleteScheduleEvent)
