import { SET_SCHEDULE_EVENTS } from "./mutations";

const initialState = {
    scheduleEvents: []
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SCHEDULE_EVENTS:
            return { ...state, scheduleEvents: action.payload }

        default:
            return state
    }
}

export default reducer
