import styled from 'styled-components';

export const ProfileContainer = styled.div`
    width: 100%;
    padding: .5em 1em;
    box-sizing: border-box;
    background: ${props => props.backgroundOff ? "transparent" : "linear-gradient(346.34deg, #025F88 -25.62%, #C33C2A 198.58%)"};
    height: 91px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 500px){
        height: auto;
    }

    .actions {
        display: flex;
        justify-content: flex-end;

        .edit-profile {
            margin-right: 10px;
        }
    }
`;