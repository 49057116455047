// Libraries
import 'luxon'
import React, { useState, useEffect } from "react"
import LuxonUtils from '@date-io/luxon';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"

// Styles


function DatePickerExtended ({ value, onChange = () => ({}), readOnly }) {
    // States
    const [selectedDate, setSelectedDate] = useState(null)

    // Methods
    const handleDateChange = (date) => {
        setSelectedDate(date)
        onChange(date)
    }

    // Watchers
    useEffect(() => {
        setSelectedDate(value)
    }, [value])


    return (
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <DatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    readOnly={readOnly}
                    value={selectedDate}
                    onChange={handleDateChange}/>
            </MuiPickersUtilsProvider>
    )
}


export default DatePickerExtended
