// Libraries
import React from "react"
import { Link } from "react-router-dom"

// Structure Components
// import {Container} from "../../Assets/Structure/Structure"

// Partials
import ScheduleEvents from "./ScheduleEvents/ScheduleEvents"
import ScheduleAvailabilities from "./ScheduleAvailabilities/ScheduleAvailabilities"

// Styles
import { ScheduleContainer, Container } from "./styled"
import Module from "../../Components/Module/Module";
import StyledButton from "../../Components/StyledButton";

import settingIcon from "../../Assets/icons/settings.svg"



function Schedule({ match }) {

    return (
        <ScheduleContainer>
            <Container
            fixed
            top="0"
            fluid
            left="0"
            ml="270px"
            maxWidth="calc(100% - 270px)"
            mt="90px"
            backgroundColor="white">
                <Module noPadding>
                    <Container
                    fluid
                    flex
                    padding="10px"
                    alignCenter
                    justifyEnd>
                        <Link to={`/appointment-schedule/settings`}>
                            <StyledButton icon={settingIcon} iconButton />
                        </Link>
                    </Container>
                </Module>
            </Container>
            <Container mt="80px">
                <ScheduleEvents />
            </Container>
        </ScheduleContainer>
    )
}

export default Schedule
