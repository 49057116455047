import styled from 'styled-components'
import {blueDefault, primary, secondary} from '../../Assets/colorPallet/colorPallet'

export const RxButtonContainer = styled.div`
    pointer-events: ${props => props.disabled ? "none" : "all"};
    cursor: ${props => props.disabled ? "normal" : "pointer"};
   
    #rx-button{
        opacity: ${props => props.disabled ? .4 : 1};
        text-transform: ${props => props.uppercase ? "uppercase" : null};
        height: ${props => props.height ? props.height : "40px"};
        width: ${props => props.width ? props.width : props.auto ? "auto" : "100%"};
        border-radius: 8px;
        padding: 0 20px;
        border: ${props => {
            if (props.kind === "primary-outlined") {
                return `1px solid ${blueDefault}`
            }
        }};
        font-size: ${props => props.fontSize ? `${props.fontSize} !important` : null};
       
        background-color: ${props => {
            if (props.kind === "primary") return blueDefault
            else if (props.kind === "depressed") return "#e7e7e7"    
            else if (
                props.kind === "outlined"
                || props.kind === "primary-outlined"
                || props.kind === "secondary-outlined"
            ) return "transparent"
            else return null;
        }};    
        color: ${props => {
            if (props.kind === "primary") {
                  return "white" 
            } else if (props.kind === "secondary") { 
                return "#333"
            } else if (props.kind === "primary-outlined") {
                return blueDefault
            } else {
                return null
            }
        }};    
    }

    .roomiex-icon_container{
        div:nth-child(1){
            display: flex;
        }
    }

    .MuiButton-text{
        font-size: 14px !important;
        letter-spacing: .1em;
        padding: 6px 14px;
    }

    .MuiButton-text{
        text-transform: unset;
    }
`
