import axios from "axios"
import {API_URL} from "../config";


export default class IntegrationHandler {
    static async enableIntegration() {
        try {
            const storeId = localStorage.getItem("store")
            const authToken = localStorage.getItem("token")
            const headers = { headers: { "x-sas-auth": authToken } }

            const payload = { action: "enable" }

            const response = await axios.post(
                `${API_URL}/admin/stores/${storeId}/integration`,
                payload,
                headers
            )

            return response.data
        } catch (error) {
            throw error
        }
    }
}
