import React from 'react'
import { Button, IconButton } from '@material-ui/core'
import { RxButtonContainer } from './styled'
import SvgIcon from "../SvgIcon/SvgIcon";
import { Container } from "../../Assets/Structure/Structure";
import InlineLoading from "../InlineLoading/InlineLoading";

export default function StyledButton({
     loading = false,
     fluid = false,
     width,
     height,
     kind,
     fontSize,
     outlined = false,
     slimPadding = false,
     iconButton = false,
     icon,
     iconSize,
     color,
     className,
     children,
     type,
     onClick = () => ({})
 }){

    return (
        <RxButtonContainer
            fluid={fluid}
            width={width}
            height={height}
            kind={kind}
            fontSize={fontSize}
            outlined={outlined}
            slimPadding={slimPadding}>
            {iconButton ? (
                <IconButton
                    onClick={() => onClick()}
                    className="styled-button--icon"
                    edge="start"
                    color="inherit"
                    aria-label="menu">
                    <SvgIcon src={icon} size={iconSize} color={color} />
                </IconButton>
            )  : (
                <Button className={className} id="rx-button" fullWidth={fluid} type={type} onClick={() => onClick()}>
                    {loading ? (
                        <InlineLoading color="white" />
                    ) : (
                        <Container flex alignCenter>
                            {icon &&<SvgIcon color={color} size="20px" src={icon} />}
                            <Container ml={ icon ? '10px' : '0' }>
                                {children}
                            </Container>
                        </Container>
                    )}
                </Button>
            )}
        </RxButtonContainer>
    )
}
