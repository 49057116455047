import store from "../../index";

export default class ScheduleEvent {
    constructor({
        id,
        date,
        startTime,
        endTime,
        participantName,
        participantPhoneNumber,
        participantEmail,
        participantNotes,
        storeId,
        name,
        locationId,
        available = 1,
        appointmentType,
        videoRoomUrl
    }) {
        this.id = id
        this.date = date
        this.startTime = startTime
        this.endTime = endTime
        this.participantName = participantName
        this.participantNotes = participantNotes
        this.locationId = locationId
        this.participantPhoneNumber = participantPhoneNumber
        this.participantEmail = participantEmail
        this.storeId = storeId
        this.name = name
        this.available = available
        this.appointmentType = appointmentType
        this.videoRoomUrl = videoRoomUrl
    }

    get storeVideoRoomUrl() {
        const storeKey = localStorage.getItem("store_key")
        return `${this.videoRoomUrl}&storeKey=${storeKey}`
    }
}
