// Libraries
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {UpdateAvailabilityContainer} from "./styled";
import {Container, Label} from "../../../../../Assets/Structure/Structure";
import {Button} from "@material-ui/core";
import {DateTime} from "luxon";
import DatePickerExtended from "../../../../../Components/DatePickerExtended/DatePickerExtended";
import TimePickerExtended from "../../../../../Components/TimePickerExtended/TimePickerExtended";
import InlineLoading from "../../../../../Components/InlineLoading/InlineLoading";
import SvgIcon from "../../../../../Components/SvgIcon/SvgIcon"
import { blueDefault } from "../../../../../Assets/colorPallet/colorPallet"
import StyledInput from "../../../../../Components/StyledInput/StyledInput";
import {updateScheduleEvent} from "../../../../../store/modules/ScheduleEvents/actions";
import linkIcon from "../../../../../Assets/icons/link.svg"
import {fetchLocations, getLocationById} from "../../../../../store/modules/locations/actions";


/**
 *
 * @param {boolean} active
 * @param {function} onClose
 */
function UpdateScheduleEvent ({
    active = false,
    onClose = () => ({}),
    locations,
    selectedScheduleEvent,
    dispatchUpdateScheduleEvent,
    dispatchFetchLocations
}) {
// States
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [loading, setLoading] = useState(false)
    const [eventName, setEventName] = useState("")
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [participantName, setParticipantName] = useState("")
    const [participantEmail, setParticipantEmail] = useState("")
    const [participantPhoneNumber, setParticipantPhoneNumber] = useState("")
    const [location, setLocation] = useState(null)

    // Watchers
    useEffect(() => {
        if (
            selectedScheduleEvent
            && selectedScheduleEvent.startTime
            && selectedScheduleEvent.endTime
            && selectedScheduleEvent.name
        ) {
            setStartTime(DateTime.fromISO(selectedScheduleEvent.startTime).toISO())
            setEndTime(DateTime.fromISO(selectedScheduleEvent.endTime).toISO())
            setEventName(selectedScheduleEvent.name)
            setParticipantName(selectedScheduleEvent.participantName)
            setParticipantEmail(selectedScheduleEvent.participantEmail)
            setParticipantPhoneNumber(selectedScheduleEvent.participantPhoneNumber)

            const storeId = selectedScheduleEvent.storeId
            dispatchFetchLocations(storeId)
        }
    }, [selectedScheduleEvent])

    useEffect(() => {
        if (locations) {
            const locationId = selectedScheduleEvent ? selectedScheduleEvent.locationId : null

            if (locationId) {
                const scheduleLocation = locations.find(loc => loc.id === selectedScheduleEvent.locationId)
                setLocation(scheduleLocation)
            }
        }
    }, [locations])

    // Methods
    const handleEventNameOnChange = (e) => {
        setEventName(e.target.value)
        setUserHasChanges(true)
    }


    const handleUpdateScheduleEvent = async () => {
        try {
            setLoading(true)
            await dispatchUpdateScheduleEvent({ scheduleEventId: selectedScheduleEvent.id }, {
                name: eventName
            })

            onClose()
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }


    return (
        <UpdateAvailabilityContainer>
            <form>
                <Container padding="0 40px 40px">
                    <Container>
                        <Label bold>Event name</Label>
                        <Container fluid mt="10px">
                            <StyledInput
                                placeholder="Add event name"
                                value={eventName}
                                onChange={handleEventNameOnChange}/>
                        </Container>
                    </Container>
                    <Container flex alignBaseline mt="20px">
                        <Container>
                            <Label bold>Date</Label>
                            <Container>
                                <Label>{DateTime.fromISO(startTime).toFormat("dd/MM/yyyy")}</Label>
                            </Container>
                        </Container>
                        <Container ml="20px">
                            <Label bold>Start</Label>
                            <Container>
                                <Label>{DateTime.fromISO(startTime).toFormat("hh:mm")}</Label>
                            </Container>
                        </Container>
                        <Container ml="10px">
                            <Label bold>End</Label>
                            <Container>
                                <Label>{DateTime.fromISO(endTime).toFormat("hh:mm")}</Label>
                            </Container>
                        </Container>
                    </Container>
                    <Container mt="20px">
                        <Label bold>Location</Label>
                        <Container>
                            <Label>{location ? `${location.address} - ${location.city}` : ""}</Label>
                        </Container>
                    </Container>
                    {selectedScheduleEvent
                    && selectedScheduleEvent.appointmentType === "VIDEO_CALL_APPOINTMENT_TYPE" && (
                        <Container mt="20px">
                            <Container flex alignCenter>
                                <SvgIcon src={linkIcon} size="16px"/>
                                <Container ml="10px">
                                    <Label bold>meeting link</Label>
                                </Container>
                            </Container>
                            <Container mt="5px" style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "20em" }}>
                                <a
                                href={selectedScheduleEvent ? selectedScheduleEvent.storeVideoRoomUrl : ""}
                                target="_blank"
                                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", oveflow: "hidden", fontSize: "12px" }}>
                                    {selectedScheduleEvent ? selectedScheduleEvent.storeVideoRoomUrl : ""}
                                </a>
                            </Container>
                        </Container>
                    )}
                    <Container mt="20px">
                        <Label bold>Client name</Label>
                        <Container mt="10px">
                            <Label>{participantName}</Label>
                        </Container>
                    </Container>
                    <Container mt="20px">
                        <Label bold>Client phone number</Label>
                        <Container mt="10px">
                            <Label>{participantPhoneNumber}</Label>
                        </Container>
                    </Container>
                    <Container mt="20px">
                        <Label bold>Client email</Label>
                        <Container mt="10px">
                            <Label>{participantEmail}</Label>
                        </Container>
                    </Container>
                    <Container mt="20px">
                        <Label bold>Client notes</Label>
                        <Container mt="10px">
                            <Label>{selectedScheduleEvent ? selectedScheduleEvent.participantNotes : ""}</Label>
                        </Container>
                    </Container>
                    <Container flex fluid alignCenter justifyEnd mt="40px">
                        <Container>
                            <Button onClick={() => onClose()}>Cancel</Button>
                        </Container>
                        <Container ml="20px" disabled={!userHasChanges}>
                            <Button
                                onClick={handleUpdateScheduleEvent}>
                                {loading ? <InlineLoading color={blueDefault}/> : "Save"}
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </form>
        </UpdateAvailabilityContainer>
    )
}

const mapStateToProps = (state) => ({
    locations: state.locations.locations
})

const mapDispatchToProps = dispatch => ({
    dispatchUpdateScheduleEvent({ scheduleEventId }, { name }) {
        dispatch(updateScheduleEvent({ scheduleEventId }, { name }))
    },

    dispatchFetchLocations(storeId) {
        dispatch(fetchLocations(storeId))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateScheduleEvent)
