import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import Feedback from '../../../Components/Feedback/Feedback';
import ChatInput from '../../../Components/ChatInput/ChatInput';
import chatIcon from '../../../Assets/icons/chat.svg';
import smileChatIcon from '../../../Assets/icons/smile-chat.svg';
import { Text, Span } from '../../../Assets/Structure/Structure'
import { WebchatBodyContainer, Overlay } from './styled';
import ChatMessages from '../ChatMessages/ChatMessages';
import { WEBCHAT_API_URL } from '../../../config';
import { setCurrentChat, setShowChatsOnMobile } from '../../../store/modules/webchat/actions'

class WebchatBody extends React.Component{

    state = { 
        newMessages: 2,
        messages: [],
        hasFile: false
    }

    componentDidMount(){
        this.props.setCurrentChat({})
    }

    componentWillReceiveProps(props){

        if (Object.getOwnPropertyNames(props.chatCredentials).length > 0){
            this.fetchMessages(props.currentChat, props.chatCredentials)
        }
    }

    componentDidUpdate(prevProps){
        if (Object.getOwnPropertyNames(this.props.currentChat).length > 0 && this.props.currentChat !== prevProps.currentChat){
            if (Object.getOwnPropertyNames(this.props.chatCredentials).length > 0){
                this.fetchMessages(this.props.currentChat, this.props.chatCredentials)
            }
        }
    }

    fetchMessages = (currentChat, chatCredentials) => {
        const storeId = localStorage.getItem('store')
        const headers = {
            headers: {"x-auth-token": chatCredentials.twilioAuthToken , "x-account-sid": chatCredentials.accountSid}
        }

        axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/locations/${currentChat.locationId}/messages?chatId=${currentChat.chatId}`, headers)
        .then(res => {
            this.setState({ messages: res.data })
        })
    }

    checkForFile = (hasFile) => {
        this.setState({ hasFile: hasFile })
    }

    hideChats = () => this.props.setShowChatsOnMobile(false)

    handleNewMessageOptmistic = (newMessage) => {
        let messages  = this.state.messages
        messages.push(newMessage)
        this.setState({ messages })
    }



    render(){

        const { messages } = this.state;
        const { currentChat } = this.props;


        const newMessagesFeedback = (
            <div>
                <Text alignCenter color="#333" fontSize="24px">You have new messages!</Text>
                <Text alignCenter color="#888" fontSize="18px">You have new messages!</Text>
            </div>
        )

        const noMessagesToRead = (
            <div>
                <Text lh="ChatMessages34px" maxWidth="15em" alignCenter color="#333" fontSize="24px">
                    <Span fw="600">Iuhul!</Span> You've read everything there is to read.
                </Text>
            </div>
        )

        const noMessages = (
            <div>
                <Text lh="ChatMessages34px" maxWidth="15em" alignCenter color="#333" fontSize="24px">
                   You don't have any messages at the moment
                </Text>
            </div>
        )

        return(

            <React.Fragment>
            {this.props.showChatsOnMobile && <Overlay onClick={this.hideChats} />}
            <WebchatBodyContainer
                active={Object.getOwnPropertyNames(currentChat).length !== 0 ? true : false}
                hasFile={this.state.hasFile}
            >
                {Object.getOwnPropertyNames(currentChat).length !== 0 && this.props.chatGroupsAmount > 0 ? (
                    <React.Fragment>
                        <ChatMessages messages={messages} hasFile={this.state.hasFile} />
                        <ChatInput newMessageOptmistic={this.handleNewMessageOptmistic} handleFileChange={this.checkForFile} />
                    </React.Fragment>
                ) : (
                    this.props.newMessagesAmount > 0  && this.props.chatGroupsAmount > 0 ? (
                    <Feedback
                     svgColor="#D0D0D0"
                     ml="0"
                     svgSize="100px"
                     direction="column"
                     iconPath={chatIcon}
                     text={newMessagesFeedback} />
                     ) : this.props.newMessagesAmount === 0 && this.props.chatGroupsAmount > 0 ? (
                    <Feedback
                     svgColor="#D0D0D0"
                     svgSize="100px"
                     direction="column"
                     ml="0"
                     iconPath={smileChatIcon}
                     text={noMessagesToRead} />
                     ) : this.props.chatGroupsAmount === 0 ? (
                        <Feedback
                        svgColor="#D0D0D0"
                        ml="0"
                        svgSize="100px"
                        direction="column"
                        iconPath={chatIcon}
                        text={noMessages} />
                    ): null

                )}
            </WebchatBodyContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    currentChat: state.webchat.currentChat,
    chatCredentials: state.webchat.chatCredentials,
    needsResponseAmount: state.webchat.needsResponseAmount,
    newMessagesAmount: state.webchat.newMessagesAmount,
    showChatsOnMobile: state.webchat.showChatsOnMobile,
    chatGroupsAmount: state.webchat.chatGroupsAmount
})

const mapDispatchToProps = dispatch => ({
    setCurrentChat(currentChat){
        dispatch(setCurrentChat(currentChat))
    },

    setShowChatsOnMobile(showChatsOnMobile){
        dispatch(setShowChatsOnMobile(showChatsOnMobile))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(WebchatBody);
