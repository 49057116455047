// Colors
import { blueDefault } from "../../Assets/colorPallet/colorPallet"


class FullCalendarEvent {
    constructor({
        id,
        title,
        start,
        end,
        display,
        backgroundColor = blueDefault
    }) {
        this.id = id
        this.title = title
        this.start = start
        this.end = end
        this.display = display
        this.backgroundColor = backgroundColor
    }
}

export default FullCalendarEvent
