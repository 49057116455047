export default class ScheduleAvailability {
    constructor({
        id,
        name = "Availability",
        startTime,
        endTime,
        storeId
    }) {
        this.id = id
        this.startTime = startTime
        this.endTime = endTime
        this.storeId = storeId
        this.name = name
    }
}
