    // Libraries
import React from "react"
import ReactSVG from "react-svg"
import {Button, Dialog} from "@material-ui/core"
import IconButton from '@material-ui/core/IconButton';

// Structure Components
import {Container, Label, Title} from "../../Assets/Structure/Structure"

// Styles
import {DialogContainer} from "./styled"

// Icons
import closeIcon from "../../Assets/icons/close.svg";
import trashIcon from "../../Assets/icons/trash.svg"


function DialogV2 ({
    onClose = () => ({}),
    onDelete = () => ({}),
    active = false,
    showDeleteAction = false,
    children,
    title,
    subtitle,
    maxWidth="md"
 }) {
    return (
        <Dialog
        className="dialog"
        style={{ borderRadius: "20em", padding: "20px" }}
        open={active}
        onClose={() => onClose()}
        maxWidth={maxWidth}
        fullWidth>
            <DialogContainer>
                <Container flex alignCenter fluid justifyEnd padding="20px 20px 0">
                    {showDeleteAction && (<Container>
                        <IconButton onClick={() => onDelete()}>
                            <ReactSVG
                                src={trashIcon}
                                className="dialog--close-icon"/>
                        </IconButton>
                    </Container>)}
                    <Container>
                        <IconButton onClick={() => onClose()}>
                            <ReactSVG
                                src={closeIcon}
                                className="dialog--close-icon"/>
                        </IconButton>
                    </Container>
                </Container>
                {title && (
                    <Container padding="0 40px 20px">
                        <Title>{title}</Title>
                        {subtitle &&  (
                            <Container mt="10px">
                                <Label>{subtitle}</Label>
                            </Container>
                        )}
                    </Container>
                )}
                <Container>
                    {children}
                </Container>
            </DialogContainer>
        </Dialog>
    )
}

export default DialogV2
