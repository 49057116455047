// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import queryString from "query-string";
import Video from 'twilio-video'

// Structure Components
import { Label, Container } from "../../../Assets/Structure/Structure"

// Partials
import Participant from "./VideoParticipant/VideoParticipant";
import RequestVideoRoomPasswordDialog from "./RequestVideoRoomPasswordDialog/RequestVideoRoomPasswordDialog";

// Styles
import { VideoRoomContainer, LocalParticipantsContainer, RemoteParticipantsContainer } from "./styled"

// Store Actions
import { generateVideoChatAccessToken } from "../../../store/modules/videoChat/actions"
import Button from "../../../Components/Button/Button";

//Assets
import micIcon from "../../../Assets/icons/mic.svg"
import micOffIcon from "../../../Assets/icons/mic-off.svg"
import phoneIcon from "../../../Assets/icons/phone-video.svg"
import videoIcon from "../../../Assets/icons/video-chat.svg"
import videoOffIcon from "../../../Assets/icons/video-off.svg"


function VideoRoom(props) {

    const [room, setRoom] = useState(null)
    const [token, setToken] = useState(null)
    const [roomName, setRoomName] = useState("")
    const [participants, setParticipants] = useState([])
    const [storeHasEndedCall, setStoreHasEndedCall] = useState(true)
    const [callHasEnded, setCallHasEnded] = useState(false)
    const [storeKey, setStoreKey] = useState(null)
    const [cameraOn, setCameraOn] = useState(true)
    const [audioOn, setAudioOn] = useState(true)
    const [showRequestPasswordDialog, setShowRequestPasswordDialog] = useState(false)
    const [videoRoomId, setVideoRoomId] = useState(null)
    const [isOnMobile, setIsOnMobile] = useState(false)

    useEffect(async () => {
        if (window.innerWidth <= 620) {
            setIsOnMobile(true)
        } else {
            setIsOnMobile(false)
        }

        const queryParams = queryString.parse(window.location.search)

        if (queryParams) {
            const videoRoomName = queryParams.videoRoomName
            const storeKeyParam = queryParams.storeKey
            const roomId = queryParams.videoRoomId

            if (roomId && !storeKeyParam) {
                setVideoRoomId(roomId)
                setShowRequestPasswordDialog(true)
            } else {
                setShowRequestPasswordDialog(false)
                const storeName = localStorage.getItem("store_name")
                const accessToken = await props.generateVideoChatAccessToken(videoRoomName, storeName)
                setToken(accessToken)
                setRoomName(videoRoomName)
                setStoreKey(storeKeyParam)
            }
        }

        disconnectUserOnCloseBrowser()
    }, [])

    useEffect(() => {
        if (!token) {
            // props.onClose(false)
        } else {
            const participantConnected = participant => {
                console.log({ participant })
                if (participants && participants.length > 0) {
                    setParticipants([...participant, participant])
                } else {
                    setParticipants([participant])
                }
            }

            const participantDisconnected = participant => {
                if (participants && participants.length > 0) {
                    console.log("disconnect", { participant })
                    setParticipants(participants.filter(p => p !== participant))
                }
            }

            Video.connect(token, { name: roomName })
                .then(async (room) => {
                    setRoom(room)
                    await room.on('participantConnected', participantConnected)
                    await room.on('participantDisconnected', participantDisconnected)
                    room.participants.forEach(participantConnected)
                    setCameraOn(true)
                    setAudioOn(true)
                })
                .catch(error => {
                    setCameraOn(false)
                    setAudioOn(false)
                })

            return () => {
                setRoom(currentRoom => {
                    if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                        currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
                            trackPublication.track.stop();
                        });
                        currentRoom.disconnect();
                        return null;
                    } else {
                        return currentRoom;
                    }
                });
            };
        }
    }, [storeKey, token, roomName])

    useEffect(() => {

    }, [room])


    let remoteParticipants = []
    if (participants && participants.length > 0) {
        remoteParticipants = participants.map(participant => (
            <Participant
                key={participant.sid}
                participant={participant}
                cameraOn={cameraOn}
                audioOn={audioOn}/>
        ));
    }

    const handleLogout = () => {
        setToken(null)
        setParticipants([])
        setCallHasEnded(true)
        room.localParticipant.videoTracks.forEach(videoTrack => videoTrack.track.disable())

        const authToken = localStorage.getItem("token")

        if (authToken && storeKey) {
            setStoreHasEndedCall(true)
        } else {
            setStoreHasEndedCall(false)
        }
    }

    const handleCamera = () => {
        setCameraOn(!cameraOn)
    }

    const handleAudio = () => {
        setAudioOn(!audioOn)
    }

    const handleAccessCodeValidatedWithSuccess = async () => {
        const queryParams = queryString.parse(window.location.search)

        if (queryParams) {
            const videoRoomName = queryParams.videoRoomName
            const participantName = queryParams.participantName

            setShowRequestPasswordDialog(false)
            const accessToken = await props.generateVideoChatAccessToken(videoRoomName, participantName)
            setToken(accessToken)
            setRoomName(videoRoomName)
        }
    }

    const disconnectUserOnCloseBrowser = () => {
        window.addEventListener('beforeunload', () => {
            setCameraOn(false)
            handleLogout()
        })
    }

    return (
        <>
            <VideoRoomContainer active={false} isOnMobile={isOnMobile}>
                    <div className="room">
                        {remoteParticipants && remoteParticipants.length > 0 && (
                          <Container zIndex="100" absolute left="0" padding="20px">
                               <Label color="white" fontSize="18px" bold>Call with: {participants[0].identity}</Label>
                            </Container>
                        )}
                        <Container flex>
                            {callHasEnded ? (
                                <Container fluid flex justifyCenter alignCenter column height="100vh">
                                    <Label color="white" fontSize="16px">Call has ended</Label>
                                    {storeHasEndedCall && storeKey && (
                                        <Container mt="20px">
                                            <a href="/webchat"><Button
                                            height="40px"
                                            padding="0 20px"
                                            borderRadius="8px"
                                            color="white"
                                            text="Go back to Webchat"
                                            onClick={() => {}}/></a>
                                        </Container>
                                    )}
                                </Container>
                            ) : (
                                participants.length === 0 ? (
                                    <Container
                                        className="video-room-container--waiting-participants-container"
                                        height="100vh"
                                        flex
                                        alignCenter
                                        justifyCenter fluid>
                                        <Label color="white" fontSize="16px">Waiting for participant to join...</Label>
                                    </Container>
                                ) : (
                                    <RemoteParticipantsContainer>
                                        {remoteParticipants}
                                    </RemoteParticipantsContainer>
                                )
                            )}
                            <LocalParticipantsContainer>
                                <div className="local-participant">
                                    {room ? (
                                        <Participant
                                            width="200px"
                                            key={room.localParticipant.sid}
                                            participant={room.localParticipant}
                                            isLocalParticipant={true}
                                            cameraOn={cameraOn}
                                            audioOn={audioOn}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </LocalParticipantsContainer>
                            {!callHasEnded && (
                                <Container fixed bottom="0" fluid flex alignCenter justifyCenter mb="20px" className="video-room--actions-container">
                                <Container>
                                    <Button
                                        bgColor={audioOn ? 'rgba(51, 51, 51, 0.6)' : 'white'}
                                        bgColorOnHover={audioOn ? 'rgba(51, 51, 51, 0.45)' : '#ffffffc7'}
                                        borderRadius="50%"
                                        width="60px"
                                        height="60px"
                                        padding="0"
                                        hasIcon
                                        onlyIcon
                                        iconPath={audioOn ? micIcon : micOffIcon}
                                        onClick={handleAudio}/>
                                </Container>
                                <Container ml="20px">
                                    <Button
                                        bgColor="#EB5757"
                                        bgColorOnHover="#eb5757bf"
                                        borderRadius="50%"
                                        width="70px"
                                        height="70px"
                                        padding="0"
                                        hasIcon
                                        onlyIcon
                                        iconPath={phoneIcon}
                                        onClick={handleLogout}/>
                                </Container>
                                <Container ml="20px">
                                    <Button
                                        bgColor={cameraOn ? 'rgba(51, 51, 51, 0.6)' : 'white'}
                                        bgColorOnHover={cameraOn ? 'rgba(51, 51, 51, 0.45)' : '#ffffffc7'}
                                        borderRadius="50%"
                                        width="60px"
                                        height="60px"
                                        padding="0"
                                        hasIcon
                                        onlyIcon
                                        iconPath={cameraOn ? videoIcon : videoOffIcon}
                                        onClick={handleCamera}/>
                                </Container>
                            </Container>)}
                        </Container>
                    </div>
            </VideoRoomContainer>
            <RequestVideoRoomPasswordDialog
            videoRoomId={videoRoomId}
            active={showRequestPasswordDialog}
            onClose={() => setShowRequestPasswordDialog(false)}
            onSuccess={handleAccessCodeValidatedWithSuccess}/>
            </>
    )
}

const mapDispatchToProps = dispatch => ({
    async generateVideoChatAccessToken(videoRoomName, participantName) {
        const accessToken = await dispatch(generateVideoChatAccessToken(videoRoomName, participantName))
        return accessToken
    }
})

export default connect(null, mapDispatchToProps)(VideoRoom)
