import React, { useState } from 'react'
import axios from 'axios'
import Modal from '../../../../Components/Modal/Modal'
import { Container, Label, Input } from '../../../../Assets/Structure/Structure'
import Button from '../../../../Components/Button/Button'
import { WEBCHAT_API_URL, API_URL } from '../../../../config'

export default function AddTwilioNumberDialog(props){

    const [twilioNumber, setTwilioNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const [twilioNumberSid, setTwilioNumberSid] = useState("")

    const handleChange = (e, type) => {
        const { value } = e.target
        if (type === 'setTwilioNumber'){
            setTwilioNumber(value)
        } else if (type === "setTwilioNumberSid"){
            setTwilioNumberSid(value)
        } else {
            return null
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setLoading(true)

        const storeId = localStorage.getItem('store')
        const authToken = localStorage.getItem('token')

        const headers = { headers: { "x-sas-Auth": authToken } };


        axios.post(`${API_URL}/admin/stores/${storeId}/integration`, { action: "enable" }, headers)
        .then((response) => {
            const payload = {
                number: twilioNumber,
                sid: twilioNumberSid,
                storeKey: response.data.store_key,
                storeSecret: response.data.store_secret,
            }

            axios.post(`${WEBCHAT_API_URL}/stores/${storeId}/twilioPhone`, payload, headers)
            .then(() => props.onSuccess(payload))
            .catch(() => {})
            .finally(() => {
                props.onClose(false)
                setLoading(false)
            })

        })
        .catch(() => this.setState({ showError: true }))
    }

    return (
        <Modal showClose title="Registry your twilio number" isModalOpen={() => props.onClose(false)}>
            <form onSubmit={handleSubmit} style={{ padding: "1em" }}>
                <Container mt="20px">
                    <Label>Twilio number:</Label>
                    <Container mt="10px">
                        <Input type="number" onChange={(e) => handleChange(e, "setTwilioNumber")} value={twilioNumber} required/>
                    </Container>
                </Container>
                <Container mt="20px">
                    <Label>Twilio number sid:</Label>
                    <Container mt="10px">
                        <Input onChange={(e) => handleChange(e, "setTwilioNumberSid")} value={twilioNumberSid} required/>
                    </Container>
                </Container>
                <Container flex justifyEnd alignCenter mt="40px">
                    <Container flex alignCenter>
                        <Container>
                            <Button
                            padding="0"
                            width="120px"
                            height="46px"
                            onClick={() => props.onClose(false)}
                            borderRadius="5px"
                            color="#333"
                            bgColor="#ddd"
                            bgColorOnHover="#ccc"
                            text="Cancel" />
                        </Container>
                        <Container ml="10px">
                            <Button
                            padding="0"
                            width="120px"
                            height="46px"
                            loading={loading}
                            borderRadius="5px"
                            color="white"
                            type="submit"
                            text="Save" />
                        </Container>
                    </Container>
                </Container>
            </form>
        </Modal>
    )
}
