// Libraries
import styled from "styled-components"


export const DialogContainer = styled.div`
  .dialog--close-icon {
    svg {
      display: flex;
    }
  }
`

