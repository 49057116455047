import React from "react";
import { Link } from "react-router-dom";
import { EditProfileButton, EditProfileContainer } from "./styled";
import ReactSVG from 'react-svg';
import editIcon from '../../../../Assets/icons/edit.svg';

export default class EditProfile extends React.Component {

  render() {

    const { userRole } = this.props;

    return (
      <React.Fragment>
        <EditProfileContainer className="edit-profile">
          {userRole === "seller" ? (
            <a onClick={this.props.onClick}>
              <ReactSVG src={editIcon} />
              {/* <EditProfileButton>Edit profile</EditProfileButton> */}
            </a>
          ) : (
            <Link to="/edit-profile" style={{ textDecoration: "none" }} onClick={this.handleEditProfile}>
              <ReactSVG src={editIcon} />
              {/* <EditProfileButton>Edit profile</EditProfileButton> */}
            </Link>
          )}  
        </EditProfileContainer>
      </React.Fragment>
    );
  }
}
