import React, { Component } from "react";
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/index';
import "./App.css";
import Login from "./Pages/Login/Login";
import Application from "./Pages/Application/Application";
import SellerLogin from "./Pages/LocationLogin/SellerLogin";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import ChoosePlatform from './Pages/ChoosePlatform/ChoosePlatform';
import Stores from './Pages/Stores/Stores';
import Accordion from './Components/Accordion/Accordion';
import { API_URL } from "./config";
import VideoRoom from "./Containers/Webchat/VideoRoom/VideoRoom";

class App extends Component {


    componentDidMount() {
        const authToken = localStorage.getItem("token");

        if (authToken !== null) {

            const headers = { headers: { "x-sas-Auth": authToken } }

            axios.get(`${API_URL}/private/sessions`, headers)
                .then(() => { })
                .catch(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_store");
                    localStorage.removeItem("user_email");
                    localStorage.removeItem("widget_only");
                    localStorage.removeItem("store");
                    localStorage.removeItem("store_services");
                    localStorage.removeItem("role");
                    localStorage.removeItem("is_admin");
                    localStorage.removeItem("logged_in");
                })

        }

    }

    render() {

        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route exact path="/auth/change-password" component={ChangePassword} />
                        <Route exact path="/stores" component={Stores} />
                        <Route exact path="/review-requests/choose-platform" component={ChoosePlatform} />
                        <Route exact path="/" render={() => (<Redirect to="/login" />)} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/seller" component={SellerLogin} />
                        <Route exact path="/video-chat" component={VideoRoom} />
                        <Route exact path="/accordion" component={Accordion} />
                        <Application handleUserLoggedIn={this.handleUserLoggedIn} />
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default App;
