// Libraries
import axios from "axios"
import {SCHEDULE_API_URL} from "../config";


export default class StoreSettingHandler {
    static generateHeaders() {
        const authToken = localStorage.getItem("token")
        const headers = { headers: { "x-sas-Auth": authToken } }

        return headers
    }

    static async setStoreSetting({ storeId }, { settingKey, settingValue }) {
        try {
            const headers = this.generateHeaders()
            const payload = { settingKey, settingValue }

            await axios.post(
                `${SCHEDULE_API_URL}/stores/${storeId}/settings`,
                payload,
                headers
            )
        } catch (error) {
            throw error
        }
    }

    static async fetchStoreOpeningHours({ storeId }) {
        try {
            const headers = this.generateHeaders()

            const response = await axios.get(
                `${SCHEDULE_API_URL}/stores/${storeId}/openingHours`,
                headers
            )

            return response.data
        } catch (error) {
            throw error
        }
    }

    static async fetchNotificationEmail({ storeId }) {
        const headers = this.generateHeaders()

        const response = await axios.get(
            `${SCHEDULE_API_URL}/stores/${storeId}/notificationEmail`,
            headers
        )

        return response.data
    }
}
