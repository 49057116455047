// Libraries
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {UpdateAvailabilityContainer} from "./styled";
import {Container, Label} from "../../../../../Assets/Structure/Structure";
import {Button} from "@material-ui/core";
import {DateTime} from "luxon";
import DatePickerExtended from "../../../../../Components/DatePickerExtended/DatePickerExtended";
import TimePickerExtended from "../../../../../Components/TimePickerExtended/TimePickerExtended";
import {
    updateStoreAvailability
} from "../../../../../store/modules/ScheduleAvailabilities/actions";
import InlineLoading from "../../../../../Components/InlineLoading/InlineLoading";
import { blueDefault } from "../../../../../Assets/colorPallet/colorPallet"

/**
 *
 * @param {boolean} active
 * @param {function} onClose
 */
function UpdateAvailability ({
    active = false,
    onClose = () => ({}),
    selectedAvailability,
    dispatchUpdateStoreAvailability
}) {
// States
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [loading, setLoading] = useState(false)


    // Watchers
    useEffect(() => {
        if (selectedAvailability && selectedAvailability.startTime && selectedAvailability.endTime) {
            setStartTime(DateTime.fromISO(selectedAvailability.startTime).toISO())
            setEndTime(DateTime.fromISO(selectedAvailability.endTime).toISO())
        }
    }, [selectedAvailability])

    // Methods
    const handleDateOnChange = (e) => {

        const endTimeLuxon = DateTime.fromISO(endTime)
        const combinedEndDateTime = DateTime.fromObject({
            year: e.year,
            month: e.month,
            day: e.day,
            hour: endTimeLuxon.hour,
            minute: endTimeLuxon.minute,
            second: endTimeLuxon.second
        })

        setEndTime(combinedEndDateTime.toISO())
    }

    const handleStartTimeOnChange = (e) => {
        const startDate = DateTime.fromISO(startTime)
        const combinedStartDateTime = DateTime.fromObject({
            year: startDate.year,
            month: startDate.month,
            day: startDate.day,
            hour: e.hour,
            minute: e.minute,
            second: e.second
        })

        setStartTime(combinedStartDateTime.toISO())
    }

    const handleEndTimeOnChange = (e) => {
        const startDate = DateTime.fromISO(startTime)
        const combinedEndDateTime = DateTime.fromObject({
            year: startDate.year,
            month: startDate.month,
            day: startDate.day,
            hour: e.hour,
            minute: e.minute,
            second: e.second
        })

        setEndTime(combinedEndDateTime.toISO())
    }

    const handleUpdateAvailability = async () => {
        try {
            setLoading(true)
            await dispatchUpdateStoreAvailability({ availabilityId: selectedAvailability.id }, {
                startTime,
                endTime
            })

            setLoading(false)
            onClose()
        } catch (error) {
            setLoading(false)
        }
    }


    return (
        <UpdateAvailabilityContainer>
            <form>
                <Container padding="0 40px 40px">
                    <Container>
                        <Label>Date</Label>
                        <Container>
                            <DatePickerExtended
                                onChange={handleDateOnChange}
                                value={startTime}/>
                        </Container>
                    </Container>
                    <Container flex alignCenter mt="20px">
                        <Container>
                            <Label>Start</Label>
                            <Container>
                                <TimePickerExtended
                                    value={startTime}
                                    onChange={handleStartTimeOnChange}/>
                            </Container>
                        </Container>
                        <Container ml="20px">
                            <Label>End</Label>
                            <Container>
                                <TimePickerExtended
                                    value={endTime}
                                    onChange={handleEndTimeOnChange}/>
                            </Container>
                        </Container>
                    </Container>
                    <Container flex fluid alignCenter justifyEnd mt="40px">
                        <Container>
                            <Button onClick={() => onClose()}>Cancelar</Button>
                        </Container>
                        <Container ml="20px">
                            <Button
                                onClick={handleUpdateAvailability}>
                                {loading ? <InlineLoading color={blueDefault}/> : "Salvar"}
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </form>
        </UpdateAvailabilityContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    dispatchUpdateStoreAvailability({ availabilityId }, { startTime, endTime }) {
        dispatch(updateStoreAvailability({ availabilityId }, { startTime, endTime }))
    }
})

export default connect(null, mapDispatchToProps)(UpdateAvailability)
