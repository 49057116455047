// Libraries
import styled from "styled-components"

// Structure Components
import {Container as ContainerStructure} from "../../Assets/Structure/Structure"


export const ScheduleContainer = styled.div`
  padding: 40px;
  box-sizing: border-box;
`
export const Container = styled(ContainerStructure)`
  z-index: 2;
`