import React, {useEffect, useState} from "react"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"
import { Container, Label } from "../../../Assets/Structure/Structure"
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Module from "../../../Components/Module/Module"
import StyledButton from "../../../Components/StyledButton";
import backIcon from "../../../Assets/icons/arrow-left.svg"
import IntegrationHandler from "../../../handlers/IntegrationHandler";
import TimePickerExtended from "../../../Components/TimePickerExtended/TimePickerExtended";
import StoreSettingHandler from "../../../handlers/StoreSettingHandler";
import RaSnackbar from "../../../Components/RaSnackbar/RaSnackbar";
import ScheduleHandler from "../../../handlers/ScheduleHandler"
import StyledInput from "../../../Components/StyledInput/StyledInput";

function ScheduleSettings() {
    // State
    const [copyingCode, setCopyingCode] = useState(false)
    const [notificationEmail, setNotificationEmail] = useState(null)
    const [currentScheduleWidget, setCurrentScheduleWidget] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [storeOpeningHours, setStoreOpeningHours] = useState({
        openTime: null,
        closeTime: null
    })
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: ""
    })

    // Mounted
    useEffect(() => {
        handleFetchStoreOpeningHours()
    }, [])

    //watchers
    useEffect(() => {
        if (snackbar.open) {
            setTimeout(() => {
                setSnackbar({ ...snackbar, open: false })
            }, 2000)
        }
    }, [snackbar.open])

    useEffect(() => {
        if (selectedTab && selectedTab === 1) {
            handleFetchStoreScheduleWidget()
        }

        if (selectedTab && selectedTab == 2) {
            handleFetchStoreNotificationEmail()
        }
    }, [selectedTab])

    // Methods
    const handleFetchStoreOpeningHours = async () => {
        try {
            const storeId = localStorage.getItem("store")
            const fetchedStoreOpeningHours = await StoreSettingHandler.fetchStoreOpeningHours({ storeId })

            setStoreOpeningHours({
                openTime: DateTime.fromISO(fetchedStoreOpeningHours.openTime).toISO(),
                closeTime: DateTime.fromISO(fetchedStoreOpeningHours.closeTime).toISO()
            })
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Oops! Something went wrong."
            })
        }
    }

    const handleFetchStoreScheduleWidget = async () => {
        try {
            const storeId = localStorage.getItem("store")
            const storeScheduleWidget = await ScheduleHandler.fetchStoreScheduleWidget({ storeId })

            setCurrentScheduleWidget(storeScheduleWidget)
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Oops! Something went wrong."
            })
        }
    }

    const handleFetchStoreNotificationEmail = async () => {
        try {
            const storeId = localStorage.getItem("store")
            const storeNotificationEmail = await StoreSettingHandler.fetchNotificationEmail({ storeId })

            setNotificationEmail(storeNotificationEmail.settingValue)
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Oops! Something went wrong."
            })
        }
    }

    const handleTabOnChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const handleStoreOpenTimeOnChange = (openTime) => {
        setStoreOpeningHours({
            ...storeOpeningHours,
            openTime: DateTime.fromISO(openTime).toISO()
        })

        setUserHasChanges(true)
    }

    const handleStoreCloseTimeOnChange = (closeTime) => {
        setStoreOpeningHours({
            ...storeOpeningHours,
            closeTime: DateTime.fromISO(closeTime).toISO()
        })

        setUserHasChanges(true)
    }

    const handleGenerateWidget = async () => {
        try {
            setLoading(true)
            const widgetCredentials = await IntegrationHandler.enableIntegration()
            const scheduleWidget = await ScheduleHandler.createBookAppointmentWidget({
                storeId: localStorage.getItem("store"),
                storeKey: widgetCredentials.store_key,
                storeSecret: widgetCredentials.store_secret,
                storeName: localStorage.getItem("store_name")
            })

            setCurrentScheduleWidget(scheduleWidget)
        } catch (error) {
            throw error
        } finally {
            setLoading(false)
        }
    }

    const handleCopyWidgetCode = () => {
        setCopyingCode(true)

        const el = document.createElement("textarea");
        el.value = currentScheduleWidget.widgetCode
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        setTimeout(() => {
            setCopyingCode(false)
        }, 2000)
    }

    const handleSaveStoreSettings = async () => {
        try {
            setLoading(true)

            const settingsToBeUpdated = []

            if (storeOpeningHours.openTime) {
                settingsToBeUpdated.push({ settingKey: "STORE_OPEN_TIME", settingValue: storeOpeningHours.openTime })
            }

            if (storeOpeningHours.closeTime) {
                settingsToBeUpdated.push({ settingKey: "STORE_CLOSE_TIME", settingValue: storeOpeningHours.closeTime })
            }

            const storeId = localStorage.getItem("store")

            await Promise.all(settingsToBeUpdated.map(async (setting) => {
                await StoreSettingHandler.setStoreSetting({ storeId }, {
                    settingKey: setting.settingKey,
                    settingValue: DateTime.fromISO(setting.settingValue).toISOTime({ includeOffset: false })
                })
            }))

            setSnackbar({
                open: true,
                message: "Opening hours updated with success"
            })

            setUserHasChanges(false)

            setSnackbar({
                open: true,
                message: "Notification email updated with success"
            })
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Oops! Something went wrong."
            })
        } finally {
            setLoading(false)
        }
    }

    const handleNotificationEmailOnChange = (e) => {
        setNotificationEmail(e.target.value)
        setUserHasChanges(true)
    }

    const handleSaveNotificationSettings = async () => {
        try {
            setLoading(true)
            const storeId = localStorage.getItem("store")

            await StoreSettingHandler.setStoreSetting({
                storeId
            }, {
                settingKey: "STORE_NOTIFICATION_EMAIL",
                settingValue: notificationEmail
            })
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Oops! Something went wrong."
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Container>
                <Container
                    fixed
                    top="0"
                    fluid
                    left="0"
                    ml="270px"
                    mt="90px"
                    backgroundColor="white">
                    <Module noPadding>
                        <Container flex alignCenter padding="0 20px">
                            <Container>
                                <Link to="/appointment-schedule">
                                    <StyledButton iconButton icon={backIcon} iconSize="10px" />
                                </Link>
                            </Container>
                            <Container
                                ml="20px"
                                fluid
                                flex
                                alignCenter>
                                <Tabs
                                value={selectedTab}
                                onChange={handleTabOnChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered>
                                    <Tab label="Availability" />
                                    <Tab label="Widgets" />
                                    <Tab label="Notifications" />
                                </Tabs>
                            </Container>
                        </Container>
                    </Module>
                </Container>
                <Container mt="60px" padding="40px">
                    {selectedTab === 0 ? (
                        <>
                            <Label bold fontSize="24px">Opening Hours</Label>
                            <Container mt="20px">
                                <Label>
                                    Set your store's opening hours to create availability
                                    for your clients in your schedule
                                </Label>
                            </Container>

                            <Container mt="40px" maxWidth="30em">
                                <Module>
                                    <Container flex alignCenter>
                                        <Container>
                                            <Label bold>Opens at</Label>
                                            <Container>
                                                <TimePickerExtended
                                                value={storeOpeningHours.openTime}
                                                onChange={handleStoreOpenTimeOnChange}/>
                                            </Container>
                                        </Container>
                                        <Container ml="40px">
                                            <Label bold>Closes at</Label>
                                            <Container>
                                                <TimePickerExtended
                                                value={storeOpeningHours.closeTime}
                                                onChange={handleStoreCloseTimeOnChange}/>
                                            </Container>
                                        </Container>
                                    </Container>
                                    <Container
                                        flex
                                        alignCenter
                                        justifyEnd
                                        disabled={!userHasChanges}
                                        mt="40px">
                                        <StyledButton
                                            disabled={!userHasChanges}
                                            kind="primary"
                                            loading={loading}
                                            onClick={handleSaveStoreSettings}>
                                            Save
                                        </StyledButton>
                                    </Container>
                                </Module>
                            </Container>
                        </>
                    ) : selectedTab === 1 ? (
                        <>
                            <Label bold fontSize="24px">Book appointment widget</Label>
                            <Container mt="20px">
                                <Label>This widget can be used in your website to allow
                                    your customers to make an appointment easily with your store</Label>
                            </Container>
                            {!currentScheduleWidget ? (
                                <Container mt="40px" fluid maxWidth="200px">
                                    <StyledButton kind="primary" loading={loading} onClick={handleGenerateWidget}>
                                        Generate Widget
                                    </StyledButton>
                                </Container>
                            ) : (
                                <Container mt="40px" fluid maxWidth="200px">
                                    <StyledButton kind="primary" onClick={handleCopyWidgetCode}>
                                        {copyingCode ? "Copied" : "Copy code"}
                                    </StyledButton>
                                </Container>
                            )}
                        </>
                    ) : selectedTab === 2 ? (
                        <>
                            <Label bold fontSize="24px">Notifications</Label>
                            <Container mt="40px">
                                <Label bold fontSie="16px">Email</Label>
                                <Container mt="5px">
                                    <Label>The notifications will be sent to this email.</Label>
                                </Container>
                                <Container mt="20px" fluid maxWidth="40em">
                                    <StyledInput
                                        placeholder="Add email"
                                        value={notificationEmail}
                                        onChange={handleNotificationEmailOnChange} />
                                    <Container disabled={!userHasChanges} flex alignCenter justifyEnd mt="40px">
                                        <StyledButton
                                            height="40px"
                                            kind="primary"
                                            disabled={!userHasChanges}
                                            loading={loading}
                                            onClick={handleSaveNotificationSettings}>
                                            Save
                                        </StyledButton>
                                    </Container>
                                </Container>
                            </Container>
                        </>
                    ) : null}
                </Container>
            </Container>
            <RaSnackbar open={snackbar.open} message={snackbar.message} />
        </>
    )
}


export default ScheduleSettings
