// Libraries
import React from "react"
import ReactSVG from "react-svg"

// Styles
import { SvgIconContainer } from "./styled"


function SvgIcon({
     src,
     color = "#333",
     size = "24px"
}) {
    return (
        <SvgIconContainer color={color} size={size}>
            <ReactSVG src={src} className="svg-icon--container" />
        </SvgIconContainer>
    )
}

export default SvgIcon
