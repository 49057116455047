// Libraries
import React, {useEffect, useState} from "react"
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"


// Styles
import { ScheduleContainer } from "./styled"
import FullCalendarEvent from "../../Pages/Schedule/FullCalendarEvent";
import { blueDefault } from "../../Assets/colorPallet/colorPallet"


function Schedule ({
    dateClick = () => ({}),
    eventClick = () => ({}),
    select = () => ({}),
    events = [],
    datesSet = () => ({})
}) {
    const [scheduleEvents, setScheduleEvents] = useState([])

    useEffect(() => {
        if (events.length > 0) {
            setScheduleEvents(events.map((e) => new FullCalendarEvent({
                id: e.id,
                title: e.name,
                start: e.startTime,
                end: e.endTime,
                display: e.display,
                backgroundColor: !e.available && e.name !== "Availability" ? "red" : blueDefault
            })))
        } else {
            setScheduleEvents([])
        }
    }, [events])

    const handleDateRange = (dateRangeInfo) => {
        datesSet(dateRangeInfo)
    }

    return (
        <ScheduleContainer>
            <FullCalendar
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                allDaySlot={false}
                nowIndicator={true}
                selectable={true}
                datesSet={handleDateRange}
                events={scheduleEvents}
                select={(arg) => select(arg)}
                dateClick={(arg) => dateClick(arg)}
                eventClick={(arg) => eventClick(arg)}
                contentHeight="calc(100vh - 91px - 40px - 40px - 68px - 80px + 106px)"
                scrollTime="08:00:00"
            />
        </ScheduleContainer>
    )
}

export default Schedule
