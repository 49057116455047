// Libraries
import React, { useState } from "react"
import { connect } from "react-redux"
import { Container, Text } from "../../../../../Assets/Structure/Structure"

// Styles
import { DeleteAvailabilityContainer } from "./styled"
import {Button} from "@material-ui/core";
import InlineLoading from "../../../../../Components/InlineLoading/InlineLoading";
import {blueDefault} from "../../../../../Assets/colorPallet/colorPallet";

// Stores
import { deleteScheduleAvailability } from "../../../../../store/modules/ScheduleAvailabilities/actions"

function DeleteAvailability({
    onClose = () => ({}),
    onCancel = () => ({}),
    dispatchDeleteScheduleAvailability = () => ({}),
    selectedAvailability
}) {
    // States
    const [loading, setLoading] = useState(false)

    //Methods
    const handleDeleteAvailability = async () => {
        try {
            setLoading(true)
            await dispatchDeleteScheduleAvailability({ scheduleAvailabilityId: selectedAvailability.id })
            onClose()
        } catch (error) {
            throw error
        } finally {
            setLoading(false)
        }
    }


    return (
        <DeleteAvailabilityContainer>
            <Container pl="40px" pb="40px">
                <Text mt="0" mb="0">
                    Are you sure about deleting this availability? All events within this time range will be cancelled.
                </Text>
                <Container pr="40px">
                    <Container flex fluid alignCenter justifyEnd mt="40px">
                        <Container>
                            <Button onClick={() => onCancel()}>Cancelar</Button>
                        </Container>
                        <Container ml="20px">
                            <Button
                                onClick={handleDeleteAvailability}>
                                {loading ? <InlineLoading color={blueDefault}/> : "Deletar"}
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </DeleteAvailabilityContainer>
    )
}


const mapDispatchToProps = (dispatch) => ({
    async dispatchDeleteScheduleAvailability({ scheduleAvailabilityId }) {
        await dispatch(deleteScheduleAvailability({ scheduleAvailabilityId }))
    }
})


export default connect(null, mapDispatchToProps)(DeleteAvailability)
