import React from 'react';
import { ProfileContainer } from './styled';
import Logout from './Logout/Logout';
import UserDetail from './UserDetail/UserDetail';
import EditProfile from './EditProfile/EditProfile';

export default class Profile extends React.Component{

    handleLoggedIn = (loggedIn) => this.props.loggedIn(loggedIn);

    render(){   

        const userRole = localStorage.getItem("role");

        return(
            <ProfileContainer backgroundOff={this.props.backgroundOff}>
                <div class="actions">
                    <EditProfile onClick={this.props.onClick} userRole={userRole} />
                    <Logout loggedIn={this.handleLoggedIn} />
                </div>
                <UserDetail 
                    onClick={this.props.handleEditProfile}
                    userName={this.props.userName} 
                    userEmail={this.props.userEmail}  />
            </ProfileContainer>
        )
    }
}