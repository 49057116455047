// Libraries
import 'luxon'
import React, { useState, useEffect } from "react"
import LuxonUtils from '@date-io/luxon';
import {TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"


function TimePickerExtended ({ value, readOnly, onChange = () => ({}) }) {
    // States
    const [selectedDate, setSelectedDate] = useState(null)

    // Methods
    const handleDateChange = (date) => {
        setSelectedDate(date)
        onChange(date)
    }

    // Watchers
    useEffect(() => {
        setSelectedDate(value)
    }, value)


    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <TimePicker
            margin="normal"
            readOnly={readOnly}
            variant="inline"
            id="time-picker"
            value={selectedDate}
            onChange={handleDateChange}/>
        </MuiPickersUtilsProvider>
    )
}


export default TimePickerExtended
