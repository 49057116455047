// Libraries
import styled from "styled-components"


export const ScheduleEventsContainer = styled.div`
  margin-top: -106px;
  position: relative;
  z-index: 10;
  width: calc(100% - 20px);
`
