import React from "react";
import axios from "axios";
import {Route, Redirect, Switch} from "react-router-dom";
import { AppContainer, Column, AppBodyContainer } from "../../Assets/Structure/Structure";
import Dashboard from "../Dashboard/Dashboard";
import Reviews from "../Reviews/Reviews";
import Webchat from '../Webchat/Webchat';
import LocationsAnalytics from "../LocationsAnalytics/LocationsAnalytics";
import Team from "../Team/Team";
import StoreLocation from "../StoreLocation/StoreLocation";
import Reports from "../Reports/Reports";
import SideMenu from "../../Components/SideMenu/SideMenu";
import Header from "../../Components/Header/Header";
import EditProfile from "../EditProfile/EditProfile";
import Faq from "../Faq/Faq"
import { API_URL } from "../../config";
import TwilioNumber from "../admin/TwilioNumber/TwilioNumber";
import WebchatSettings from '../WebchatSettings'
import WebchatAnalytics from "../admin/WebchatAnalytics/WebchatAnalytics";
import SMSMarketing from "../SMSMarketing/SMSMarketing";
import VideoRoom from "../../Containers/Webchat/VideoRoom/VideoRoom";
import Schedule from "../Schedule/Schedule";
import ScheduleSettings from "../Schedule/ScheduleSettings/ScheduleSettings";

export default class Application extends React.Component {
    state = {
        loggedIn: "",
        userName: "",
        userEmail: "",
        userRole: "",
        reviewRequests: [],
        tokenHasExpired: false,
        totalReviewRequests: "",
        widgetOnly: "-1",
        chatOnly: "-1"
    };

    componentDidMount() {
        const authToken = localStorage.getItem("token");
        const headers = {
            headers: {
                "x-sas-Auth": authToken
            }
        };
        const userRole = localStorage.getItem("role");
        const chatOnly = localStorage.getItem("chat_only");
        const widgetOnly = localStorage.getItem("widget_only");

        this.setState({userRole, widgetOnly, chatOnly})

        axios.get(`${API_URL}/private/sessions/`, headers)
        .then(res => {
            this.setState({
                userName: res.data.user_name,
                userEmail: res.data.user_email,
                userRole: res.data.role,
                loggedIn: true,
                chatOnly: res.data.chat_only,
                widgetOnly: res.data.widge_only
            });
        })
        .catch((err) => {
            this.setState({tokenHasExpired: true})
            localStorage.removeItem("token")
            localStorage.removeItem("logged_in");
        })
    }

    handleUserNameMutation = userName => this.setState({userName});

    handleLoggedIn = loggedIn => this.setState({loggedIn});

    handleUpdateReviewRequests = (reviewRequests, totalReviewRequests) => {
        this.setState({reviewRequests, totalReviewRequests})
    }

    render() {
        const {widgetOnly, tokenHasExpired, userRole} = this.state;

        if (tokenHasExpired)
            return <Redirect to="/login"/>;
        if (userRole === "seller")
            return <Redirect to="/seller"/>;

        return (
            <AppContainer>
                <Column>
                    <SideMenu
                     userName={this.state.userName}
                     userEmail={this.state.userEmail}
                     userRole={this.state.userRole}
                     loggedIn={this.handleLoggedIn}/>
                </Column>
                <Column fluid>
                    <Header
                        widgetOnly={widgetOnly}
                        updateReviewRequests={this.handleUpdateReviewRequests}
                        loggedIn={this.handleLoggedIn}/>
                    <AppBodyContainer>
                        <Route exact path="/" component={Dashboard}/>
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route exact path="/reviews" component={Reviews}/>
                        <Route exact path="/locations-analytics" component={LocationsAnalytics}/>
                        <Route exact path="/team" component={Team}/>
                        <Route
                         exact
                         path="/reports"
                         render={() => <Reports
                         reviewRequests={this.state.reviewRequests}
                         totalReviewRequests={this.state.totalReviewRequests}/>}/>

                        <Route exact path="/store-location" component={StoreLocation}/>
                        <Route exact path="/webchat" component={() => <Webchat {...this.props} />} />
                        <Route exact path ="/sms-marketing" component={() => <SMSMarketing {...this.props} />} />
                        <Route exact path="/appointment-schedule" component={() => <Schedule {...this.props} />} />
                        <Route exact path="/appointment-schedule/settings" component={() => <ScheduleSettings {...this.props} />} />
                        <Route
                         exact
                         path="/edit-profile"
                         render={() => (<EditProfile
                         type="manager"
                         currentUserName={this.state.userName}
                         currentUserEmail={this.state.userEmail}
                         userName={this.handleUserNameMutation}/>)}/>
                         <Route exact path="/faq" component={Faq} />
                         <Route exact path="/webchat/settings" component={WebchatSettings} />
                         <Route exact path="/admin/twilio-number" component={TwilioNumber} />
                         <Route exact path="/admin/webchat-analytics" component={WebchatAnalytics} />

                    </AppBodyContainer>
                </Column>
            </AppContainer>
        );
    }
}
