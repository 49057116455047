import React from 'react';
import moment from 'moment'
import { DateTime } from 'luxon';
import ReactSVG from 'react-svg'
import { ChatMessageContainer, OpenImage } from './styled';
import { Text, Row, Label, Image, Container, Span } from '../../Assets/Structure/Structure';
import openImageIcon from '../../Assets/icons/download.svg'

export default class ChatMessage extends React.Component{

    state = {
        timeLabel: ""
    }

    componentDidMount(){
        this.generateTimeLabel()
    }

    componentDidUpdate(props) {
        if (props.message !== this.props.message) {
            this.generateTimeLabel()
        }
    }

    generateTimeLabel = () => {
        const { message } = this.props
        const today = DateTime.local().set({ hour: 0, minute: 0, second: 0 }).toMillis()
        const yesterday = DateTime.fromMillis(today).minus({ day: 1 }).set({ hour: 0, minute: 0, second: 0 }).toMillis()
        const createdAtInMilliseconds = DateTime.fromISO(message.createdAt).toMillis()


        const time = DateTime.fromISO(message.createdAt).toFormat("t")

        if ((createdAtInMilliseconds < today) && (createdAtInMilliseconds >= yesterday)){
            this.setState({ timeLabel: `yesterday at ${time}` })
        } else if ((createdAtInMilliseconds >= today)){
            this.setState({ timeLabel: `today at ${time}` })
        } else {
            const date = DateTime.fromISO(message.createdAt).toFormat("MM/dd/yyyy")
            this.setState({ timeLabel: `${date} - ${time}` })
        }
    }

    render(){
        const { message, key } = this.props;

        return(
            <>
            <ChatMessageContainer author={message.author}>
                {message.author !== "store" && <Row><Label bold>{message.author}</Label></Row>}
                {message.author === "store" && (
                    <Container>
                        <Label color="#025F88" fontSize="12px">sent by <Span bold>{message.staffName}</Span></Label>
                    </Container>
                )}

                {message.imageUrl &&
                    <Container mt="10px">
                        <a href={message.imageUrl} download target="_blank">
                        <Image src={message.imageUrl}>
                            <OpenImage>
                                <ReactSVG src={openImageIcon} className="open-image-icon" />
                            </OpenImage>
                        </Image>
                        </a>
                    </Container>
                }

                <Text ls=".05em" mb="0" mt="0">{message.text}</Text>

                <Container mt="10px" flex fluid justifyEnd alignCenter>
                    <Label color="#888" fontSize="12px">{this.state.timeLabel}</Label>
                </Container>

            </ChatMessageContainer>
                {message && message.images && message.images.map((image) => (
                    <Container mt="20px">
                        <a href={image} download target="_blank">
                            <Image src={image}>
                                <OpenImage>
                                    <ReactSVG src={openImageIcon} className="open-image-icon" />
                                </OpenImage>
                            </Image>
                        </a>
                    </Container>
                ))}
            </>
        )
    }
}
