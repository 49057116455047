// Libraries
import styled from "styled-components"


export const SvgIconContainer = styled.div`
    display:flex;
    width: ${props => props.size ? props.size : null};
    height: ${props => props.size ? props.size : null};
    align-items: center;
    justify-content: center;
  
  .svg-icon--container{
    div:nth-child(1) {
      &:first-child {
        display: flex;
      }
    }
  }
  
  svg {
    width: ${props => props.size ? props.size : null};
    height: auto;
    fill: ${props => props.color ? props.color : null};
    
    g {
      fill: ${props => props.color ? props.color : null};
      path {
        fill: ${props => props.color ? props.color : null};
      }
    }
  }
`

