import React from "react";
import { MenuContainer, Label, DataAnalysisContainer, ManagementContainer, FaqContainer } from "./styled";
import MenuOptions from "./MenuOptions/MenuOptions";
import dashboardIcon from "../../../Assets/icons/dashboard.svg";
import analyticsIcon from "../../../Assets/icons/analytics.svg";
import reviewsIcon from "../../../Assets/icons/reviews.svg";
import reportsIcon from "../../../Assets/icons/reports.svg";
import faqIcon from '../../../Assets/icons/faq.svg';
import exchangeIcon from '../../../Assets/icons/exchange.svg';
import chatIcon from '../../../Assets/icons/chat.svg';
import settingsIcon from '../../../Assets/icons/settings.svg'
import twilioIcon from '../../../Assets/icons/twilio.svg'
import smsIcon from "../../../Assets/icons/mobile-sms.svg"
import calendarIcon from "../../../Assets/icons/calendar.svg"
import videoChatIcon from "../../../Assets/icons/video-chat-purple.svg"

export default class Menu extends React.Component {

  state = {
    currentTab: "/dashboard",

  }

  componentDidMount(){
    const url = window.location;
    let path = url.pathname;
    this.setState({ currentTab: path })
  }


  handleMenuActive = (isMenuActive) => {
    this.props.isMenuActive(isMenuActive)
  }

  handleCurrentTab = (currentTab) => this.setState({ currentTab });

  render() {
    const services = JSON.parse(localStorage.getItem('store_services') || '[]');
    const userRole = localStorage.getItem("role");
    let analyticOptions = [{ value: "Dashboard", path: "/dashboard", icon: dashboardIcon }]
    let managementOptions = [{ value: "Store Location", path: "/store-location", icon: analyticsIcon }]
    let privateActions = []
    let settingsOptions = [{ value: "FAQ", path: "/faq", icon: faqIcon }]

    if (userRole === "sas-team"){
      privateActions = [
        { value: "Change Store", path: "/stores", icon: exchangeIcon },
        { value: "Twilio Number", path: "/admin/twilio-number", icon: twilioIcon },
        { value: "Webchat Analytics", path: "/admin/webchat-analytics", icon: analyticsIcon }
      ]
    }

    if (!!services.find(item => item.name === 'TextChat')) {
      managementOptions.push({ value: "Team", path: "/team", icon: dashboardIcon })

      if (!services.find(item => item.name === 'VideoCall')) {
        managementOptions.push({ value: "Webchat", path: "/webchat", icon: chatIcon });
      }

      settingsOptions.push(
        { value: "Webchat Settings", path: "/webchat/settings", icon: settingsIcon },
      )
    }

    if (!!services.find(item => item.name === 'ReviewAlert')) {
      if (!managementOptions.find(item => item.value === "Team")) {
        managementOptions.push({value: "Team", path: "/team", icon: dashboardIcon})
      }

      analyticOptions.push(
          { value: "Location Analytics", path: "/locations-analytics", icon: analyticsIcon },
          { value: "Reviews", path: "/reviews", icon: reviewsIcon },
          { value: "Reports", path: "/reports", icon: reportsIcon }
      )
    }

    if (!!services.find(item => item.name === 'SMSMarketing')) {
      managementOptions.push({ value: "SMS Marketing", path: "/sms-marketing", icon: smsIcon })
    }

    if (!!services.find(item => item.name === 'VideoCall')) {
      if (!!services.find(item => item.name === 'TextChat')) {
        managementOptions.push({ value: "Webchat", path: "/webchat", icon: chatIcon });
      }

      managementOptions.push({ value: "Schedule", path: "/appointment-schedule", icon: calendarIcon })
    }

    return (
      <MenuContainer>
        <DataAnalysisContainer>
          {services.find(item => item.name === 'TextChat') ? <Label>Data analysis</Label> : null}
          <MenuOptions
            isMenuActive={this.handleMenuActive}
            handleCurrentTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
            options={analyticOptions}
          />
        </DataAnalysisContainer>

        <ManagementContainer>
        <Label>Management</Label>
          <MenuOptions
            isMenuActive={this.handleMenuActive}
            handleCurrentTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
            options={managementOptions}
          />
        </ManagementContainer>

        {userRole === "sas-team" && (
        <ManagementContainer>
        <Label>SAS Team only</Label>
          <MenuOptions
            isMenuActive={this.handleMenuActive}
            handleCurrentTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
            options={privateActions}
          />
        </ManagementContainer>)}

        <FaqContainer>
          <hr />
          <MenuOptions
          handleCurrentTab={this.handleCurrentTab}
          currentTab={this.state.currentTab}
          isMenuActive={this.handleMenuActive}
          options={settingsOptions} />
        </FaqContainer>
      </MenuContainer>
    );
  }
}
