import axios from "axios";

import {SCHEDULE_API_URL} from "../../../config";
import ScheduleAvailability from "./model";
import {SET_STORE_AVAILABILITIES} from "./mutations";
import store from "../../index";


/**
 * This action can be used to create an availability for a specific store
 *
 * @param {DateTime} startTime
 * @param {DateTime} endTime
 */
export const createStoreAvailability = ({
    startTime,
    endTime
}) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"
        const headers = { headers: { "x-sas-Auth": authToken } };

        const payload = {
            startTime,
            endTime
        }

        const response = await axios.post(`${SCHEDULE_API_URL}/stores/${storeId}/availabilities`, payload, headers)

        if (response.data.reason && response.data.reason === 101) {
            return
        }

        const createdStoreAvailability = response.data

        const storeAvailability =  new ScheduleAvailability({ ...createdStoreAvailability })
        const storeAvailabilities = [...store.getState().scheduleAvailabilities.storeAvailabilities]
        storeAvailabilities.push(storeAvailability)

        dispatch({ type: SET_STORE_AVAILABILITIES, payload: storeAvailabilities })
    } catch (error) {
        throw error
    }
}


/**
 * This action can be used to fetch all store availabilities within a specific time range
 *
 * @param { DateTime } startTime
 * @param { DateTime } endTime
 *
 */
export const fetchStoreAvailabilities = ({ startTime, endTime }) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"
        const headers = { headers: { "x-sas-Auth": authToken } }

        const response = await axios.get(
            `${SCHEDULE_API_URL}/stores/${storeId}/availabilities?startTime=${startTime}&endTime=${endTime}`,
            headers
        )

        const storeAvailabilities = response.data.map((storeAvailability) => new ScheduleAvailability({
            ...storeAvailability,
        }))

        dispatch({ type: SET_STORE_AVAILABILITIES, payload: storeAvailabilities })
    } catch (error) {
        throw new Error(error)
    }
}


export const updateStoreAvailability = ({ availabilityId }, { startTime, endTime }) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"
        const headers = { headers: { "x-sas-Auth": authToken } }

        const payload = {
            startTime,
            endTime
        }

        const response = await axios.put(
            `${SCHEDULE_API_URL}/stores/${storeId}/availabilities/${availabilityId}`,
            payload,
            headers
        )

        const updatedStoreAvailability = response.data
        const storeAvailability =  new ScheduleAvailability({ ...updatedStoreAvailability })
        const storeAvailabilities = [...store.getState().scheduleAvailabilities.storeAvailabilities]

        const updatedStoreAvailabilities = storeAvailabilities.map((availability) => {
            if (availability.id === updatedStoreAvailability.id) {
                return storeAvailability
            }

            return availability
        })

        dispatch({ type: SET_STORE_AVAILABILITIES, payload: updatedStoreAvailabilities })
    } catch (error) {
        throw error
    }
}


export const deleteScheduleAvailability = ({ scheduleAvailabilityId }) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"
        const headers = { headers: { "x-sas-Auth": authToken } }

        await axios.delete(`${SCHEDULE_API_URL}/stores/${storeId}/availabilities/${scheduleAvailabilityId}`, headers)

        const storeAvailabilities = [...store.getState().scheduleAvailabilities.storeAvailabilities]
        const updatedStoreAvailabilities = storeAvailabilities.filter((storeAvailability) => {
            if (storeAvailability.id === parseInt(scheduleAvailabilityId)) {
                return false
            }

            return true
        })

        dispatch({ type: SET_STORE_AVAILABILITIES, payload: updatedStoreAvailabilities })
    } catch (error) {
        throw error
    }
}
