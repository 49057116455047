// Libraries
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import InputAdornment from '@material-ui/core/InputAdornment';

import {CreateAvailabilityDialogContainer} from "./styled";
import {Container, Label} from "../../../../Assets/Structure/Structure";
import {Button} from "@material-ui/core";
import DialogV2 from "../../../../Components/DialogV2/DialogV2";
import {DateTime} from "luxon";
import DatePickerExtended from "../../../../Components/DatePickerExtended/DatePickerExtended";
import TimePickerExtended from "../../../../Components/TimePickerExtended/TimePickerExtended";
import {createScheduleEvent} from "../../../../store/modules/ScheduleEvents/actions";
import InlineLoading from "../../../../Components/InlineLoading/InlineLoading";
import { blueDefault } from "../../../../Assets/colorPallet/colorPallet"
import StyledInput from "../../../../Components/StyledInput/StyledInput";

/**
 *
 * @param {boolean} active
 * @param {function} onClose
 */
function CreateScheduleEventDialog ({
    active = false,
    onClose = () => ({}),
    dispatchCreateScheduleEvent,
    selectedStartTime,
    selectedEndTime,
}) {
    // States
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [eventName, setEventName] = useState("")
    const [loading, setLoading] = useState(false)
    const [participantName, setParticipantName] = useState("")
    const [participantPhoneNumber, setParticipantPhoneNumber] = useState("")
    const [participantEmail, setParticipantEmail] = useState("")
    const [formIsValid, setFormIsValid] = useState(false)


    // Watchers
    useEffect(() => {
        if (selectedStartTime && !selectedEndTime) {
            setStartTime(DateTime.fromISO(selectedStartTime).toISO())
            setEndTime(DateTime.fromISO(selectedStartTime).toISO())
        }

        if (selectedStartTime && selectedEndTime) {
            setStartTime(DateTime.fromISO(selectedStartTime).toISO())
            setEndTime(DateTime.fromISO(selectedEndTime).toISO())
        }
    }, [selectedStartTime, selectedEndTime])

    useEffect(() => {
        if (
            startTime
            && endTime
            && eventName.length > 0
            && participantName.length > 0
            && participantEmail.length > 0
            && participantPhoneNumber.length > 0
        ) {
            setFormIsValid(true)
        } else {
            setFormIsValid(false)
        }
    }, [startTime, endTime, eventName, participantEmail, participantPhoneNumber, participantEmail])

    // Methods
    const handleEventNameOnChange = (e) => {
        setEventName(e.target.value)
    }

    const handleDateOnChange = (e) => {
        setStartTime( DateTime.fromISO(e).toISO())

        const endTimeLuxon = DateTime.fromISO(endTime)
        const combinedEndDateTime = DateTime.fromObject({
            year: e.year,
            month: e.month,
            day: e.day,
            hour: endTimeLuxon.hour,
            minute: endTimeLuxon.minute,
            second: endTimeLuxon.second
        })

        setEndTime(combinedEndDateTime.toISO())
    }

    const handleStartTimeOnChange = (e) => {
        const startDate = DateTime.fromISO(startTime)
        const combinedStartDateTime = DateTime.fromObject({
            year: startDate.year,
            month: startDate.month,
            day: startDate.day,
            hour: e.hour,
            minute: e.minute,
            second: e.second
        })

        setStartTime(combinedStartDateTime.toISO())
    }

    const handleEndTimeOnChange = (e) => {
        const startDate = DateTime.fromISO(startTime)
        const combinedEndDateTime = DateTime.fromObject({
            year: startDate.year,
            month: startDate.month,
            day: startDate.day,
            hour: e.hour,
            minute: e.minute,
            second: e.second
        })

        setEndTime(combinedEndDateTime.toISO())
    }

    const handleParticipantNameOnChange = (e) => {
        setParticipantName(e.target.value)
    }

    const handleParticipantPhoneNumberOnChange = (e) => {
        setParticipantPhoneNumber(e.target.value)
    }

    const handleParticipantEmailOnChange = (e) => {
        setParticipantEmail(e.target.value)
    }

    const handleCreateScheduleEvent = async () => {
        try {
            setLoading(true)
            await dispatchCreateScheduleEvent({
                startTime,
                endTime,
                name: eventName,
                participantName,
                participantPhoneNumber,
                participantEmail
            })
            setLoading(false)
            onClose()
        } catch (error) {
            setLoading(false)
        }
    }


    return (
        <CreateAvailabilityDialogContainer>
            <DialogV2
            active={active}
            onClose={() => onClose()}
            maxWidth="sm"
            title="Create Event">
                <form>
                    <Container padding="0 40px 40px">
                        <Container>
                            <Label bold>Event name</Label>
                            <Container fluid mt="10px">
                                <StyledInput
                                    placeholder="Add event name"
                                    value={eventName}
                                    onChange={handleEventNameOnChange}/>
                            </Container>
                        </Container>
                        <Container flex alignBaseline mt="20px">
                            <Container>
                                <Label bold>Date</Label>
                                <Container mt="16px">
                                    <DatePickerExtended
                                        onChange={handleDateOnChange}
                                        value={selectedStartTime}/>
                                </Container>
                            </Container>
                            <Container ml="20px">
                                <Label bold>Start</Label>
                                <Container>
                                    <TimePickerExtended
                                        value={selectedStartTime}
                                        onChange={handleStartTimeOnChange}/>
                                </Container>
                            </Container>
                            <Container ml="10px">
                                <Label bold>End</Label>
                                <Container>
                                    <TimePickerExtended
                                        value={selectedEndTime}
                                        onChange={handleEndTimeOnChange}/>
                                </Container>
                            </Container>
                        </Container>
                        <Container mt="20px">
                            <Label bold>Client name</Label>
                            <Container mt="10px">
                                <StyledInput
                                    placeholder="Add client name"
                                    value={participantName}
                                    onChange={handleParticipantNameOnChange}/>
                            </Container>
                        </Container>
                        <Container mt="20px">
                            <Label bold>Client phone number</Label>
                            <Container mt="10px">
                                <StyledInput
                                    placeholder="Add client phone number"
                                    startAdornment={<InputAdornment position="start">+1</InputAdornment>}
                                    value={participantPhoneNumber}
                                    type="number"
                                    onChange={handleParticipantPhoneNumberOnChange}/>
                            </Container>
                        </Container>
                        <Container mt="20px">
                            <Label bold>Client email</Label>
                            <Container mt="10px">
                                <StyledInput
                                    placeholder="Add client email"
                                    value={participantEmail}
                                    onChange={handleParticipantEmailOnChange}/>
                            </Container>
                        </Container>
                        <Container flex fluid alignCenter justifyEnd mt="40px">
                            <Container>
                                <Button onClick={() => onClose()}>Cancel</Button>
                            </Container>
                            <Container ml="20px" disabled={!formIsValid}>
                                <Button
                                onClick={handleCreateScheduleEvent}>
                                    {loading ? <InlineLoading color={blueDefault}/> : "Save"}
                                </Button>
                            </Container>
                        </Container>
                    </Container>

                </form>
            </DialogV2>
        </CreateAvailabilityDialogContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    dispatchCreateScheduleEvent({
        startTime,
        endTime,
        name,
        participantName,
        participantPhoneNumber,
        participantEmail
    }) {
        dispatch(createScheduleEvent({
            startTime,
            endTime,
            name,
            participantName,
            participantPhoneNumber,
            participantEmail
        }))
    }
})

export default connect(null, mapDispatchToProps)(CreateScheduleEventDialog)
