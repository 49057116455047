// LOCATIONS
import { SET_LOCATIONS } from "./mutations"
import axios from "axios";
import {API_URL} from "../../../config";

export const setLocations = locations => dispatch => {
    dispatch({
        type: SET_LOCATIONS,
        payload: locations
    })
}

export const fetchLocations = (storeId) => async (dispatch) => {
    try {
        const authToken = localStorage.getItem("token");
        const headers = { headers: { "x-sas-Auth": authToken } };

        const response = await axios.get(`${API_URL}/admin/stores/${storeId}/locations`, headers)
        const locations = response.data

        dispatch({ type: SET_LOCATIONS, payload: locations })

        return locations
    } catch (error) {
        throw new Error(error)
    }
}

