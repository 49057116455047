// Libraries
import axios from "axios"
import { DateTime } from "luxon"

// Models
import ScheduleEvent from "./model"

// Config
import {SCHEDULE_API_URL} from "../../../config"

// Store
import store from "../../index"

// Mutations
import {SET_SCHEDULE_EVENTS} from "./mutations"


/**
 * This action can be used to create a schedule event for a specific store
 *
 * @param {DateTime} startTime
 * @param {DateTime} endTime
 */
export const createScheduleEvent = ({
    startTime,
    endTime,
    name,
    participantName,
    participantPhoneNumber,
    participantEmail
}) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"
        const headers = { headers: { "x-sas-Auth": authToken } };

        const payload = {
            startTime,
            endTime,
            name,
            participantName,
            participantPhoneNumber,
            participantEmail
        }

        const response = await axios.post(`${SCHEDULE_API_URL}/stores/${storeId}/events`, payload, headers)

        if (response.data.reason && response.data.reason === 101) {
            return
        }

        const createdScheduleEvent = response.data

        const scheduleEvent =  new ScheduleEvent({ ...createdScheduleEvent })

        const scheduleEvents = [...store.getState().scheduleEvents.scheduleEvents]
        scheduleEvents.push(scheduleEvent)

        dispatch({ type: SET_SCHEDULE_EVENTS, payload: scheduleEvents })
    } catch (error) {
        throw error
    }
}

/**
 * This action can be used to fetch all schedule events within a specific time range
 *
 * @param { DateTime } startTime
 * @param { DateTime } endTime
 *
 */
export const fetchScheduleEvents = ({ startTime, endTime }) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"
        const headers = { headers: { "x-sas-Auth": authToken } }

        const response = await axios.get(
            `${SCHEDULE_API_URL}/stores/${storeId}/events?startTime=${startTime}&endTime=${endTime}`,
            headers
        )


        const scheduleEvents = response.data.map((scheduleEvent) => {
            const startTime =  DateTime.fromISO(scheduleEvent.startTime, { zone: "utc" })
                .set({ seconds: 0, milliseconds: 0 })
                .toISO()
                .replace(".000Z", "")

            const endTime = DateTime.fromISO(scheduleEvent.endTime, { zone: "utc" })
                .set({ seconds: 0, milliseconds: 0 })
                .toISO()
                .replace(".000Z", "")

            return new ScheduleEvent({
                ...scheduleEvent,
                startTime,
                endTime
            })
        })

        dispatch({ type: SET_SCHEDULE_EVENTS, payload: scheduleEvents })
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteScheduleEvent = ({ scheduleEventId }) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"
        const headers = { headers: { "x-sas-Auth": authToken } }

        await axios.delete(
            `${SCHEDULE_API_URL}/stores/${storeId}/events/${scheduleEventId}`,
            headers
        )

        const scheduleEvents = [...store.getState().scheduleEvents.scheduleEvents].filter((scheduleEvent) => {
            if (scheduleEvent.id === parseInt(scheduleEventId)) {
                return false
            }

            return true
        })

        dispatch({ type: SET_SCHEDULE_EVENTS, payload: scheduleEvents })
    } catch (error) {
        throw error
    }
}

export const updateScheduleEvent = ({ scheduleEventId }, { name }) => async (dispatch) => {
    try {
        const storeId = localStorage.getItem("store")
        const authToken = "0x37e37cff861a4992decc94cf4abad7573768c0ebb0f4017a134d1bd550fba2e5"

        const headers = { headers: { "x-sas-Auth": authToken } }
        const payload = { name }

        const response = await axios.put(
            `${SCHEDULE_API_URL}/stores/${storeId}/events/${scheduleEventId}`,
            payload,
            headers
        )

        const updatedScheduleEvent = response.data

        const scheduleEvents = [...store.getState().scheduleEvents.scheduleEvents].map((scheduleEvent) => {
            if (scheduleEvent.id === parseInt(scheduleEventId)) {
                return updatedScheduleEvent
            }

            return scheduleEvent
        })

        dispatch({ type: SET_SCHEDULE_EVENTS, payload: scheduleEvents })
    } catch (error) {
        throw error
    }
}


// GETTERS
export const getScheduleEventById = (eventId) => () => {
    const scheduleEvent = [...store.getState().scheduleEvents.scheduleEvents].find((scheduleEvent) => {
        if (scheduleEvent.id === parseInt(eventId)) {
            return true
        }

        return false
    })

    return scheduleEvent
}
