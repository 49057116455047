import {BOOK_APPOINTMENT_S3_URL} from "../config";

export default class ScheduleWidget {
    constructor({
        id,
        storeId,
        storeKey,
        storeName,
        storeSecret
    }) {
        this.id = id
        this.storeId = storeId
        this.storeKey = storeKey
        this.storeName = storeName
        this.storeSecret = storeSecret
    }

    get widgetCode() {
        return `
            <script>
                (function() {
                    let head = document.querySelector('head');
                    
                    let cdn1 = document.createElement("link");
                    cdn1.setAttribute("rel", "stylesheet"), 
                    cdn1.setAttribute("type", "text/css"),
                    cdn1.setAttribute("charset", "UTF-8")
                    cdn1.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"),
            
                    head.appendChild(cdn1);
            
                    let cdn2 = document.createElement("link");
                    cdn2.setAttribute("rel", "stylesheet"), 
                    cdn2.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"),
            
                    head.appendChild(cdn2);
            
                    let cdnGoogleFonts = document.createElement("link");
                    cdnGoogleFonts.setAttribute("rel", "stylesheet"), 
                    cdnGoogleFonts.setAttribute("type", "text/css"),
                    cdnGoogleFonts.setAttribute("href", "https://fonts.googleapis.com/css?family=Roboto:400,500,700"),
            
                    head.appendChild(cdnGoogleFonts);
            
                    let e = document.createElement("div");
                    e.setAttribute("id", "txtar-book-appointment-widget-entrypoint"), 
                    e.setAttribute("data-txtar-store-name", "${this.storeName}"),
                    e.setAttribute("data-txtar-store-id", "${this.storeId}"),
                    e.setAttribute("data-txtar-store-key", "${this.storeKey}");
                    
                    // Here you set the class of the button element which is going to be used to open the 
                    // schedule appointment dialog. By default we are setting "txtar-book-appointment--button". 
                    // However feel free to use any other class that suits you best.
                    e.setAttribute("data-target-element-class", "txtar-book-appointment--button")
                    
                    let raContainer = document.getElementById("ra_beacon_container");
                    if (raContainer) 
                            raContainer.appendChild(e);
                    else
                            document.body.appendChild(e);
                    let t = document.createElement("script");
                    t.type = "text/javascript", 
                    t.src = "${BOOK_APPOINTMENT_S3_URL}", 
                    document.getElementsByTagName("head")[0].appendChild(t)
                })()
            </script>
        `
    }
}
