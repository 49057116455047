// Libraries
import styled from "styled-components"

export const ScheduleContainer = styled.div`
  .fc-timegrid.fc-timeGridWeek-view.fc-view {
    max-height: calc(100vh - 40px - 48px - 80px - 91px - 40px  106px);
  }
  
  .fc-view-harness.fc-view-harness-active {
    background-color: white;
  }
`

