// Libraries
import styled from "styled-components"


export const VideoRoomContainer = styled.div`
  width: 100%;
  background-color: #666;
  box-sizing: border-box;
  overflow: hidden;
  height: 100vh;
  
  .room {
    width: ${props => props.active ? "calc(100% - 375px)" : "100%"};
  }
  
  .video-room--actions-container {
    width: ${props => props.active ? "calc(100% - 375px)" : "100%"};
  }
  
  @media only screen and (max-width: 600px) {
    .video-room-container--waiting-participants-container{
      height: 50vh;
    }
  }
`

export const LocalParticipantsContainer = styled.div`
  position: absolute;
  right: ${props => props.active ? "375px" : 0};
  bottom: 0;
  padding: 20px;
  
  @media only screen and (max-width: 600px) {
    padding: 0;
    top: 0;
    right: 20px;
    
    .local-participant {
      margin-top: 20px;
    }
  }
`

export const RemoteParticipantsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  //transform: scale(1.6);
  background-color: black;
  
  @media only screen and (max-width: 600px) {
    //height: 100vh;
  }
`


