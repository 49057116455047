import { SET_STORE_AVAILABILITIES } from "./mutations";

const initialState = {
    storeAvailabilities: []
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_STORE_AVAILABILITIES:
            return { ...state, storeAvailabilities: action.payload }
            break
        default:
            return state
    }
}

export default reducer
