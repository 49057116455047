import styled from 'styled-components';

export const ChatInputContainer = styled.div`
    background-color: white;
    height: ${props => props.expand ? "300px" : " 70px"};
    position: ${props => props.expand ? "fixed" : null};
    width: ${props => props.expand ? "100%" : null};
    display: flex;
    bottom: ${props => props.expand ? "0" : null};
    align-items: center;
    justify-content: center;
    
    .chat-input--expand-container{
      display: none;
    }
    
    .chat-input--arrow-icon{
      width: 14px;
      height: 14px;
      fill: white;
      transform: ${props => props.expand ? "rotate(0)" : "rotate(180deg)"};
    }
    
    @media only screen and (max-width: 600px) {
      .chat-input--expand-container{
        display: flex;
      }
    }
`;

export const InputForm = styled.div`
    background-color: #f8f8f8;
    display: flex;
    align-items: ${props => props.expand ? "baseline" : "center"};
    width: 100%;
    border: 1px solid #E6E6E6;
    height: ${props => props.expand ? "280px" : "60px"};
    width: ${props => props.expand ? "100%" : null};
    bottom: ${props => props.expand ? "0" : null};
    margin: 0 10px;
    border-radius: 4px;
    padding: 0 1em;
    justify-content: ${props => props.expand ? "baseline" : "space-between"};
`;

export const Input = styled.textarea`
    height: ${props => props.expand ? "220px" : "26px"};
    border: none;
    background-color: #f8f8f8;
    width: ${props => props.expand ? "100%" : "70%"};
    outline: none;
    padding-bottom: ${props => props.expand ? "5em !important" : null};
    padding-top: ${props => props.expand ? "20px !important" : null};
    box-sizing: border-box;
    padding: 0 1em;
    line-height: 1.6em;
`;

export const ActionsWrapperContainer = styled.div`
    display: flex;
    align-items: center;

    .upload-button {
      border: none;
      background: transparent;
      padding: 0;
      cursor:pointer;
      margin-right: 10px;
      height: 24px;
    }

`
export const FileContentContainer = styled.div`
  margin: 0 10px;

  .file-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #E6E6E6;
    font-size: 13px;
    padding: 10px;

    button {
      border: none;
      background: #d23636;
      border-radius: 100px;
      cursor: pointer;
      display: block;
      text-align: center;
      padding: 0;
      line-height: 22px;
      width: 23px;
      height: 23px;

      div {
        display: inline-block;
        width: 8px;
        height: 10px;
      }

      svg {
        width: 8px;
        height: 10px;
      }
    }
  }
`