// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles
import { UpdateAvailabilityDialogContainer } from "./styled";

// Components
import DialogV2 from "../../../../Components/DialogV2/DialogV2";
import {DELETE_AVAILABILITY, UPDATE_AVAILABILITY} from "../../../../utils/constants";
import UpdateAvailability from "./UpdateAvailability/UpdateAvailability";
import DeleteAvailability from "./DeleteAvailability/DeleteAvailability";


function UpdateAvailabilityDialog({
    active = false,
    selectedAvailability,
    onClose = () => ({})
}) {
    // States
    const [currentPartial, setCurrentPartial] = useState(UPDATE_AVAILABILITY)

    // Mounted
    useEffect(() => {
        setCurrentPartial(UPDATE_AVAILABILITY)
    }, [])



    return (
        <UpdateAvailabilityDialogContainer>
            <DialogV2
                active={active}
                showDeleteAction={currentPartial === UPDATE_AVAILABILITY}
                onClose={() => onClose()}
                onDelete={() => setCurrentPartial(DELETE_AVAILABILITY)}
                maxWidth="sm"
                title={currentPartial === UPDATE_AVAILABILITY ? "Update availability" : "Delete Availability"}>
                {currentPartial === UPDATE_AVAILABILITY ? (
                    <UpdateAvailability
                        selectedAvailability={selectedAvailability}
                        onClose={() => onClose()} />
                ) : currentPartial === DELETE_AVAILABILITY ? (
                    <DeleteAvailability
                        selectedAvailability={selectedAvailability}
                        onCancel={() => setCurrentPartial(UPDATE_AVAILABILITY)}
                        onClose={() => onClose()}/>
                ) : null}
            </DialogV2>
        </UpdateAvailabilityDialogContainer>
    )
}


export default connect(null, null)(UpdateAvailabilityDialog)
