// Libraries
import styled from "styled-components"
import {blueDefault} from "../../Assets/colorPallet/colorPallet";


export const StyledInputContainer = styled.div`
width: 100%;
    .MuiOutlinedInput-root {
      border-radius: 8px !important;
      width:100%;
    }
    
    .MuiFormControl-root {
      width: 100%;
    }
    
    input {
        border: none;
        width: 100%;
        padding: ${props => props.small ? "12px 14px !important" : null};
        font-size: ${props => props.small ? "14px !important" : null};
    }
    
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: ${blueDefault} !important;
    }
    
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
`

