// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Components
import Schedule from "../../../Components/Schedule/Schedule"

// Styles
import { ScheduleAvailabilitiesContainer } from "./styled"
import CreateAvailabilityDialog from "./CreateAvailabilityDialog/CreateAvailabilityDialog";
import {fetchStoreAvailabilities} from "../../../store/modules/ScheduleAvailabilities/actions";
import {DateTime} from "luxon";
import UpdateAvailabilityDialog from "./UpdateAvailabilityDialog/UpdateAvailabilityDialog";
import ScheduleAvailability from "../../../store/modules/ScheduleAvailabilities/model";


function ScheduleAvailabilities({ dispatchFetchStoreAvailabilities, storeAvailabilities }) {
    // States
    const [selectedStartTime, setSelectedStartTime] = useState(null)
    const [selectedEndTime, setSelectedEndTime] = useState(null)
    const [showCreateAvailabilityDialog, setShowCreateAvailabilityDialog] = useState(false)
    const [showUpdateAvailabilityDialog, setShowUpdateAvailabilityDialog] = useState(false)
    const [weekRange, setWeekRange] = useState(null)
    const [selectedAvailability, setSelectedAvailability] = useState(null)

    // Watch
    useEffect(() => {
        if (weekRange) {

            const startOfTheWeek = DateTime.fromISO(weekRange.startTime).toISODate()
            const endOfTheWeek = DateTime.fromISO(weekRange.endTime).toISODate()

            dispatchFetchStoreAvailabilities({
                startTime: startOfTheWeek,
                endTime: endOfTheWeek
            })
        }
    }, [weekRange])

    // Methods
    const handleDateClick = (e) => {
        const selectedStartTime = e.dateStr
        setSelectedStartTime(selectedStartTime)
        setShowCreateAvailabilityDialog(true)
    }

    const handleDateOnSelect = (e) => {
        setSelectedStartTime(e.startStr)
        setSelectedEndTime(e.endStr)
        setShowCreateAvailabilityDialog(true)
    }

    const handleDateRangeOnChange = (dateRangeInfo) => {
        setWeekRange({
            startTime: dateRangeInfo.startStr,
            endTime: dateRangeInfo.endStr
        })
    }

    const handleEventOnClick = (e) => {
        const {event} = e
        setShowUpdateAvailabilityDialog(true)
        const availability = new ScheduleAvailability({
            id: event.id,
            startTime: event.startStr,
            endTime: event.endStr,
        })

        setSelectedAvailability(availability)
    }

    return (
        <>
            <ScheduleAvailabilitiesContainer>
                <Schedule
                dateClick={handleDateClick}
                eventClick={handleEventOnClick}
                select={handleDateOnSelect}
                datesSet={handleDateRangeOnChange}
                events={storeAvailabilities}/>
            </ScheduleAvailabilitiesContainer>
            {showCreateAvailabilityDialog &&  (
                <CreateAvailabilityDialog
                active={showCreateAvailabilityDialog}
                onClose={() => setShowCreateAvailabilityDialog(false)}
                selectedStartTime={selectedStartTime}
                selectedEndTime={selectedEndTime}/>
            )}
            {showUpdateAvailabilityDialog && (
                <UpdateAvailabilityDialog
                active={showUpdateAvailabilityDialog}
                selectedAvailability={selectedAvailability}
                onClose={() => setShowUpdateAvailabilityDialog(false)}/>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchStoreAvailabilities({ startTime, endTime }) {
        dispatch(fetchStoreAvailabilities({ startTime, endTime }))
    }
})

const mapStateToProps = (state) => ({
    storeAvailabilities: state.scheduleAvailabilities.storeAvailabilities
})


export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAvailabilities)
