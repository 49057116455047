import axios from "axios"
import { SCHEDULE_API_URL } from "../config"

// Models
import ScheduleWidget from "../models/ScheduleWidget"


export default class ScheduleHandler {
    static async createBookAppointmentWidget({ storeId, storeKey, storeSecret, storeName }) {
        try {
            const payload = { storeKey, storeSecret, storeName }
            const response = await axios.post(
                `${SCHEDULE_API_URL}/stores/${storeId}/bookAppointmentWidget`,
                payload
            )

            const scheduleWidget = new ScheduleWidget({
                ...response.data
            })

            return scheduleWidget
        } catch (error) {
            throw error
        }
    }

    static async fetchStoreScheduleWidget({ storeId }) {
        try {
            const response = await axios.get(`${SCHEDULE_API_URL}/stores/${storeId}/bookAppointmentWidget`)

            let scheduleWidget = null
            if (response.data) {
                scheduleWidget = new ScheduleWidget({
                    ...response.data
                })

            }

            return scheduleWidget
        } catch (error) {
            throw error
        }
    }
}
