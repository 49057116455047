// LIBRARIES
import axios from "axios"
import React, { useEffect, useState } from "react"

// STRUCTURE COMPONENTS
import { Container, PageWrapper, Label, Title } from "../../../Assets/Structure/Structure"
import Module from "../../../Components/Module/Module"
import { WEBCHAT_API_URL } from "../../../config"
import Skeleton from "react-skeleton-loader"
import MessagesRepliedChart from "./MessagesRepliedChart/MessagesRepliedChart"
import StoresConversationRanking from "./StoresConversationRanking/StoresConversationRanking";
import StoresResponseRanking from "./StoresResponseRanking/StoresResponseRanking";


export default function WebchatAnalytics(props) {

    const storeName = localStorage.getItem("store_name")

    //STATE
    const [loading, setLoading] = useState(true)
    const [storeWebchatAnalytics, setStoreWebchatAnalytics] = useState({
        conversations: 0,
        repliedMessages: 0
    })
    const [allStoresWebchatAnalytics, setAllStoresWebchatAnalytics] = useState({
        conversations: 0,
        repliedMessages: 0
    })



    // MOUNTED
    useEffect(() => {
        methods.getInitialData()
    }, [])

    //METHODS
    const methods = {
        getInitialData: async() => {
            const credentials = await methods.getStoreTwilioNumber()
            methods.fetchWebchatAnalytics(credentials)
        },
        getStoreTwilioNumber: () => {
            const storeKey = localStorage.getItem('store_key')

            return new Promise((resolve, reject) => {
                axios.get(`${WEBCHAT_API_URL}/stores/${storeKey}/twilioPhone`)
                .then((response) => {

                    const credentials = response.data[0]
                    resolve(credentials)
                })
                .catch((error) => reject(error))
            })
        },

        fetchWebchatAnalytics: async (credentials) => {
            setLoading(true)

            try {
                await methods.fetchStoreWebchatAnalytics(credentials)
                await methods.fetchAllStoresWebchatAnalytics(credentials)
            }
            finally {
                setLoading(false)
            }
        },

        fetchStoreWebchatAnalytics: (credentials) => {
            const { accountSid, token } = credentials
            const headers = { headers: { "x-auth-token": token, "x-account-sid": accountSid } }
            const storeId = localStorage.getItem("store")

            return axios.get(`${WEBCHAT_API_URL}/analytics/stores/${storeId}/messages`, headers)
            .then(res => {
                setStoreWebchatAnalytics({
                    conversations: res.data.conversations,
                    repliedMessages: res.data.repliedMessages
                })
            })
            .catch(error => error)
        },

        fetchAllStoresWebchatAnalytics: (credentials) => {
            const { accountSid, token } = credentials
            const headers = { headers: { "x-auth-token": token, "x-account-sid": accountSid } }

            return axios.get(`${WEBCHAT_API_URL}/analytics/stores/messages`, headers)
            .then(res => {
                setAllStoresWebchatAnalytics({
                    conversations: res.data.conversations,
                    repliedMessages: res.data.repliedMessages
                })
            })
        }
    }

    return (
        <Container>
            <PageWrapper>
                <Container>
                    <Title>Webchat analytics</Title>
                </Container>
                <Container flex alignCenter mt="40px">
                    <Container>
                        <Module style={{ padding: "40px" }}>
                            {loading ?  (
                            <Container>
                                <Container><Skeleton /></Container>
                                <Container mt="20px"><Skeleton /></Container>
                                <Container mt="10px"><Skeleton /></Container>
                            </Container>
                            ): (
                            <>
                                <Container>
                                    <Label bold>{ storeName }</Label>
                                </Container>
                                <Container mt="20px">
                                    <Container flex alignCenter>
                                    <Container>
                                        <Label>Conversations:</Label>
                                    </Container>
                                    <Container ml="5px">
                                        <Label>{ storeWebchatAnalytics.conversations }</Label>
                                    </Container>
                                    </Container>

                                    <Container flex alignCenter mt="10px">
                                    <Container>
                                        <Label>Replied messages:</Label>
                                    </Container>
                                    <Container ml="5px">
                                        <Label>{ storeWebchatAnalytics.repliedMessages }</Label>
                                    </Container>
                                    </Container>
                                </Container>
                            </>
                            )}
                        </Module>
                    </Container>
                    <Container ml="20px">
                        <Module style={{ padding: "40px" }}>
                        {loading ?  (
                            <Container>
                                <Container><Skeleton /></Container>
                                <Container mt="20px"><Skeleton /></Container>
                                <Container mt="10px"><Skeleton /></Container>
                            </Container>
                        ): (
                        <>
                            <Container>
                                <Label bold>All stores</Label>
                            </Container>
                            <Container mt="20px">
                                <Container flex alignCenter>
                                <Container>
                                    <Label>Conversations:</Label>
                                </Container>
                                <Container ml="5px">
                                    <Label>{ allStoresWebchatAnalytics.conversations }</Label>
                                </Container>
                                </Container>

                                <Container flex alignCenter mt="10px">
                                <Container>
                                    <Label>Replied messages:</Label>
                                </Container>
                                <Container ml="5px">
                                    <Label>{ allStoresWebchatAnalytics.repliedMessages }</Label>
                                </Container>
                                </Container>
                            </Container>
                        </>
                        )}
                        </Module>
                    </Container>
               </Container>
                <Container flex mt="40px">
                    <Module style={{ padding: "40px 40px 40px 0", width: "auto" }}>
                        <MessagesRepliedChart
                        storeName={localStorage.getItem("store_name")}
                        storeWebchatAnalytics={storeWebchatAnalytics}
                        allStoresWebchatAnalytics={allStoresWebchatAnalytics} />
                        </Module>
                </Container>
                <Container mt="40px">
                    <Container mt="20px" fluid flex wrap>
                        <Container fluid maxWidth="25em" mb="40px">
                            <Container>
                                <Label fontSize="16px" bold>Stores Conversations Ranking</Label>
                            </Container>
                            <Container  mr="40px" mt="20px">
                                <StoresConversationRanking months={12} />
                            </Container>
                        </Container>
                        <Container fluid maxWidth="25em" mb="40px">
                            <Container>
                                <Label fontSize="16px" bold>Stores Conversations Ranking (last 30 days)</Label>
                            </Container>
                            <Container  mr="40px" mt="20px">
                                <StoresConversationRanking months={1} />
                            </Container>
                        </Container>
                        <Container fluid maxWidth="25em" mb="40px">
                            <Container>
                                <Label fontSize="16px" bold>Stores Response Ranking</Label>
                            </Container>
                            <Container  mr="40px" mt="20px">
                                <StoresResponseRanking />
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </PageWrapper>
        </Container>
    )
}
