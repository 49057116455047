// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles
import { UpdateAvailabilityDialogContainer } from "./styled";

// Components
import DialogV2 from "../../../../Components/DialogV2/DialogV2";
import {DELETE_SCHEDULE_EVENT, UPDATE_SCHEDULE_EVENT} from "../../../../utils/constants";
import UpdateScheduleEvent from "./UpdateScheduleEvent/UpdateScheduleEvent";
import DeleteScheduleEvent from "./DeleteScheduleEvent/DeleteScheduleEvent";


function UpdateScheduleEventDialog({
    active = false,
    selectedScheduleEvent,
    onClose = () => ({})
}) {
    // States
    const [currentPartial, setCurrentPartial] = useState(UPDATE_SCHEDULE_EVENT)

    // Mounted
    useEffect(() => {
        setCurrentPartial(UPDATE_SCHEDULE_EVENT)
    }, [])



    return (
        <UpdateAvailabilityDialogContainer>
            <DialogV2
                active={active}
                showDeleteAction={currentPartial === UPDATE_SCHEDULE_EVENT}
                onClose={() => onClose()}
                onDelete={() => setCurrentPartial(DELETE_SCHEDULE_EVENT)}
                maxWidth="sm"
                title={currentPartial === UPDATE_SCHEDULE_EVENT ? "Update event" : "Delete event"}>
                {currentPartial === UPDATE_SCHEDULE_EVENT ? (
                    <UpdateScheduleEvent
                        selectedScheduleEvent={selectedScheduleEvent}
                        onClose={() => onClose()} />
                ) : currentPartial === DELETE_SCHEDULE_EVENT ? (
                    <DeleteScheduleEvent
                        selectedScheduleEvent={selectedScheduleEvent}
                        onCancel={() => setCurrentPartial(UPDATE_SCHEDULE_EVENT)}
                        onClose={() => onClose()}/>
                ) : null}
            </DialogV2>
        </UpdateAvailabilityDialogContainer>
    )
}


export default connect(null, null)(UpdateScheduleEventDialog)
