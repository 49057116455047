import { combineReducers } from 'redux'
import locations from './modules/locations'
import integration from "./modules/integration"
import webchat from "./modules/webchat"
import smsMarketing from "./modules/smsMarketing"
import videoChat from "./modules/videoChat"
import scheduleAvailabilities from "./modules/ScheduleAvailabilities"
import scheduleEvents from "./modules/ScheduleEvents"

export default combineReducers({
    locations,
    integration,
    webchat,
    videoChat,
    smsMarketing,
    scheduleAvailabilities,
    scheduleEvents
})
