import styled from "styled-components";

export const EditProfileButton = styled.a`
  margin-top: 0.25em;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
`;

export const EditProfileContainer = styled.div`
// width: 100%;
display: flex;
justify-content: flex-end;
cursor: pointer;

svg {
  path {
    fill: #ccc;
    transition: fill 0.4s ease;
  }
}

&:hover {
  svg {
    path {
      fill: white;
    }
  }
}
`;